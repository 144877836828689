import React, { useState } from "react";
import {
  Badge,
  Button,
  Container,
  Drawer,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Stack,
  Typography,
  TextField,
} from "@mui/material";
import { Iconify } from "src/components";
import { LoadingButton } from "@mui/lab";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

function FilterTrackDrawer({
  openFilterDrawer,
  categories,
  filterStories,
  handleFilterChange,
  handleFilterClose,
  funcTracks,
  funcFilterClearTracks,
  isLoadingBtn,
  loadingBtn,
}) {
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [selectedMediaType, setSelectedMediaType] = useState("");
  const [fromDate, setFromDate] = useState("");
  const [toDate, setToDate] = useState("");
  const [selectedStatus, setSelectedStatus] = useState("");

  const categoryMap = categories?.reduce((acc, category) => {
    acc[category._id] = category.title;
    return acc;
  }, {});

  const filterTrackFunction = () => {
    let filter = { story_categories: selectedCategories };
    if (fromDate) {
      filter.start_date = fromDate;
    }
    if (toDate) {
      filter.end_date = toDate;
    }
    if (selectedStatus) {
      filter.status = selectedStatus == "active" ? true : false;
    }
    if (selectedMediaType) {
      filter.media_type =
        selectedMediaType == "image" ? "IMAGE" : "CUSTOM_VIDEO";
    }
    filterStories(filter);
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openFilterDrawer}
      onClose={handleFilterClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Filter Stories
        </Typography>
        <form>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              required
              multiple
              label="Categories"
              value={selectedCategories}
              onChange={(e) => setSelectedCategories(e.target.value)}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                  {selected.map((_id) => (
                    <MenuItem
                      key={_id}
                      sx={{
                        backgroundColor: "#f4fbea",
                        padding: "7px",
                        borderRadius: "20px",
                        border: "1px solid #87c326",
                        margin: "2px",
                      }}
                    >
                      {categoryMap[_id]}
                    </MenuItem>
                  ))}
                </div>
              )}
            >
              {categories?.length === 0 && (
                <MenuItem disabled>
                  <em>No Category found</em>
                </MenuItem>
              )}
              {categories &&
                categories.map((category) => (
                  <MenuItem key={category._id} value={category._id}>
                    {category.title}
                  </MenuItem>
                ))}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Media Type</InputLabel>
            <Select
              required
              label="Media Type"
              value={selectedMediaType}
              onChange={(e) => setSelectedMediaType(e.target.value)}
              MenuProps={MenuProps}
            >
              <MenuItem value="image">Image</MenuItem>
              <MenuItem value="video">Video</MenuItem>
            </Select>
          </FormControl>

          <Stack direction="row" spacing={2} sx={{ mb: 2 }}>
            <FormControl fullWidth>
              <TextField
                id="From"
                label="From"
                type="date"
                value={fromDate}
                onChange={(e) => setFromDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
              <small> Provide Schedule date</small>
            </FormControl>

            <FormControl fullWidth>
              <TextField
                id="To"
                label="To"
                type="date"
                value={toDate}
                onChange={(e) => setToDate(e.target.value)}
                InputLabelProps={{
                  shrink: true,
                }}
              />
            </FormControl>
          </Stack>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>

            <Select
              required
              label="Status"
              value={selectedStatus}
              onChange={(e) => setSelectedStatus(e.target.value)}
              MenuProps={MenuProps}
              renderValue={(selected) => (
                <Typography variant="body1">
                  {selectedStatus === "active" ? "Active" : "Inactive"}
                </Typography>
              )}
            >
              <MenuItem value="active">Active</MenuItem>
              <MenuItem value="inactive">Inactive</MenuItem>
            </Select>
          </FormControl>

          <Stack
            direction="column"
            alignItems="left"
            justifyContent="flex-start"
            my={1}
          >
            <LoadingButton
              className="me-3"
              loading={loadingBtn}
              variant="contained"
              startIcon={<Iconify icon="mdi:filter" />}
              style={{ marginBottom: "15px" }}
              onClick={() => {
                filterTrackFunction();
              }}
            >
              Filter
            </LoadingButton>
            <LoadingButton
              className="me-3"
              loading={isLoadingBtn}
              variant="contained"
              startIcon={<Iconify icon="mdi:filter" />}
              onClick={() => {
                funcFilterClearTracks();
                setSelectedCategories([]);
                setSelectedMediaType("");
                setFromDate("");
                setToDate("");
                //  funcTracks()
              }}
            >
              Clear Filter
            </LoadingButton>
          </Stack>
        </form>
      </Container>
    </Drawer>
  );
}

export default FilterTrackDrawer;
