import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { uploadImage, uploadAudio } from "src/DAL/uploadFiles";
import { editTrack, getTrackDetail } from "src/DAL/tracks";
import LoadingButton from "@mui/lab/LoadingButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { s3baseUrl } from "src/config/config";
import { Iconify } from "src/components";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Grid,
  CircularProgress,
} from "@mui/material";
import { toInteger } from "lodash";
import { getCategories } from "src/DAL/category";
import { addDashboardImageAndCategory } from "src/DAL/dashboardImage";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      "&:active": { bgColor: "red" },
    },
  },
};
function EditDashboardImage({
  openEditDrawer,
  setOpenEditDrawer,
  dashboadImagesAndCategories,
  functionGetDashboardImage,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState([]);
  const [trackCategory, setTrackCategory] = useState();
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState();

  //<==========Function that will edit the track=========>
  const getTrackCategories = async () => {
    const result = await getCategories();
    if (result.code == 200) {
      console.log("success");
      setTrackCategory(result.category);
    } else {
      console.log(`Error: ${result.message}`);
    }
  };

  const handleChange = (e) => {
    if (e.target.value.length <= 4) setSelectedCategory(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategory.length !== 4) {
      enqueueSnackbar("Please select 4 categories", { variant: "error" });
      return;
    }
    let images = dashboadImagesAndCategories.images;
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      formData.append("module", "track");
      const resultUpload = await uploadImage(formData);
      if (resultUpload.code == 200) {
        images = resultUpload.paths;
      }
    }
    const reqObj = {
      images,
      categories: selectedCategory,
    };
    const result = await addDashboardImageAndCategory(reqObj);
    if (result.code == 200) {
      functionGetDashboardImage()
      enqueueSnackbar("Edit successfully",{variant:"success"})
      setOpenEditDrawer(false)
    } else {
      enqueueSnackbar(result.message,{variant:"error"})
    }
  };

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      // console.log(URL.createObjectURL(selectedImage),"imagePreview11")
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);
  useEffect(() => {
    getTrackCategories();
    const arr = dashboadImagesAndCategories?.categories.map((val) => {
      return val._id;
    });
    setSelectedCategory([...arr]);
  }, [openEditDrawer]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => {
        setOpenEditDrawer(false);
        setData();
        setImagePreview();
      }}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h5" sx={{ mt: -5, mb: 5 }}>
          Edit Dashboard Image
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2}>
            <Grid item xs={imagePreview ? 8 : 12}>
              <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                <Button
                  variant="contained"
                  //   sx={{ width: "50%" }}
                  component="label"
                >
                  {!selectedImage && (
                    <>
                      Select Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  {selectedImage && (
                    <>
                      <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                    </>
                  )}
                  <input
                    hidden
                    name="image"
                    accept="image/*"
                    type="file"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                </Button>
              </FormControl>
            </Grid>
            {imagePreview ? (
              <Grid item xs={4}>
                <img
                  src={imagePreview}
                  alt="image"
                  width="100"
                  height="auto"
                />
              </Grid>
            ) : (
              <Grid item xs={4}>
                <img
                  src={s3baseUrl + dashboadImagesAndCategories?.images.small}
                  alt="image"
                  width="100"
                  height="auto"
                />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12} className="mt-3">
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Categories
              </InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCategory}
                label="Select Categories"
                onChange={handleChange}
              >
                {trackCategory &&
                  trackCategory.map((val) => {
                    return <MenuItem value={val._id}>{val.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>
          <LoadingButton
            className="mt-3"
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default EditDashboardImage;
