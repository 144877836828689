import { invokeApi } from "src/utils";
export const getDashboardImageAndCategory = async () => {
  const requestObj = {
    path: `api/dashboard_setting/get_dashboard_setting`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    // postData: data,
  };
  return invokeApi(requestObj);
};

export const addDashboardImageAndCategory = async (data) => {
  const requestObj = {
    path: `api/dashboard_setting/edit_dashboard_setting`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editDashboardImage = async (data,id) => {
    const requestObj = {
      path: `api/gallery/edit_gallery/${id}`,
      method: "PUT",
      headers: {
        "x-sh-auth": localStorage.getItem("token"),
      },
      postData: data,
    };
    return invokeApi(requestObj);
  };