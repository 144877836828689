import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getTranslateRecords, PUTStoryLanguage } from "src/DAL/story_category";
import { Drawer, Container, Typography, TextField } from "@mui/material";
import { LoadingButton } from "@mui/lab";

function StoryLanguageDrawer({
  openEditDrawer,
  setOpenLanguageDrawer,
  editCategoryId,
  language,
  setCategories,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [title, setTitle] = useState("");
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setTitle(""); // Clear the title state when the drawer opens

    const fetchTranslatedCategory = async () => {
      try {
        const response = await getTranslateRecords(editCategoryId, language);
        console.log(response, "gettranslatedrecords");
        if (response.code === 200) {
          setTitle(response.story_category.title); // Use title from response
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error occurred while fetching translated category", {
          variant: "error",
        });
      }
    };

    if (editCategoryId) {
      fetchTranslatedCategory();
    }
  }, [editCategoryId, language, enqueueSnackbar]);

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent default form submission
    if (!title || !language) {
      // Check if title or language is missing
      enqueueSnackbar("Title and language are required", { variant: "error" });
      return;
    }

    setLoading(true);

    const requestBody = {
      title,
      language,
    };

    try {
      const response = await PUTStoryLanguage(editCategoryId, requestBody);
      console.log(response, "StoryPut");
      if (response.code === 200) {
        setCategories((old) =>
          old.map((item) => {
            if (item._id === editCategoryId) {
              item.translated_record =
                response.story_category.translated_record;
            }
            return item;
          })
        );
        enqueueSnackbar(response.message, { variant: "success" });
        setOpenLanguageDrawer(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while saving the translation", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => setOpenLanguageDrawer(false)}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography
          variant="h4"
          sx={{ mt: -5, mb: 5, textTransform: "capitalize" }}
        >
          {`Category in ${language}`}
        </Typography>

        <form onSubmit={handleSubmit}>
          <TextField
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            id="outlined-basic"
            label={`name in ${language}`}
            variant="outlined"
            fullWidth
            required
          />
          <LoadingButton
            sx={{ marginTop: "20px" }}
            variant="contained"
            type="submit"
            loading={loading}
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default StoryLanguageDrawer;
