import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getStoryRecords, PutStory_Languages } from "src/DAL/story";
import {
  Drawer,
  TextField,
  Container,
  Typography,
  CircularProgress,
  Box,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function StoryDarwerLanguagesLang({
  openEditDrawer,
  setOpenLanguageDrawer,
  editCategoryId,
  language,
  setTracks,
  is_Published,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [initialLoading, setInitialLoading] = useState(true); // New state for initial loading
  const [isPublished, setIsPublished] = useState(is_Published);

  useEffect(() => {
    if (!openEditDrawer) {
      return;
    }

    setInitialLoading(true);
    setName("");
    setDescription("");
    setNotificationTitle("");
    setNotificationDescription("");
    setIsPublished(is_Published);

    const fetchTranslatedTrack = async () => {
      try {
        console.log(editCategoryId, language, "id and language...");
        const response = await getStoryRecords(editCategoryId, language);
        console.log(response, "response getStoryRecords...");
        if (response.code === 200) {
          const {
            title: name,
            description,
            notification_title,
            notification_description,
            is_published,
          } = response.story;
          setName(name);
          setIsPublished(is_published);
          setDescription(description);
          setNotificationTitle(notification_title);
          setNotificationDescription(notification_description);
        }
      } catch (error) {
        console.error(error);
        enqueueSnackbar("Error occurred while fetching translated Story", {
          variant: "error",
        });
      } finally {
        setInitialLoading(false); // Set initial loading to false after fetching data
      }
    };

    if (editCategoryId) {
      fetchTranslatedTrack();
    }
  }, [openEditDrawer, editCategoryId, language, enqueueSnackbar, is_Published]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !language) {
      enqueueSnackbar("Name and language are required", { variant: "error" });
      return;
    }
    const requestBody = {
      title: name,
      description,
      notification_title: notificationTitle,
      notification_description: notificationDescription,
      language,
    };
    setLoading(true);
    try {
      const response = await PutStory_Languages(editCategoryId, requestBody);
      if (response.code === 200) {
        setIsPublished(true);
        setTracks((old) =>
          old.map((item) => {
            if (item._id === editCategoryId) {
              item.translated_record = response.story.translated_record;
            }
            return item;
          })
        );

        enqueueSnackbar(response.message, { variant: "success" });
        setOpenLanguageDrawer(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while updating the story", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => setOpenLanguageDrawer(false)}
    >
      <Container sx={{ my: 10, width: 390 }}>
        {initialLoading ? (
          <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' ,marginTop:"130px"}}>
            <CircularProgress />
          </Box>
        ) : (
          <>
            <Typography
              variant="h4"
              sx={{ mt: -5, mb: 5, textTransform: "capitalize" }}
            >
              {`Story in ${language}`}
            </Typography>

            <form onSubmit={handleSubmit}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label={`Name in ${language}`}
                variant="outlined"
                fullWidth
                required
                sx={{ mb: 2 }}
              />
              <TextField
                value={description}
                required
                onChange={(e) => setDescription(e.target.value)}
                id="outlined-description"
                label={`Description in ${language}`}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                multiline
                rows={4}
              />
              <TextField
                value={notificationTitle}
                required
                onChange={(e) => setNotificationTitle(e.target.value)}
                id="outlined-notification-title"
                label={`Notification Title in ${language}`}
                variant="outlined"
                fullWidth
                sx={{ mb: 2 }}
                disabled={Boolean(isPublished)}
              />
              <TextField
                value={notificationDescription}
                required
                onChange={(e) => setNotificationDescription(e.target.value)}
                id="outlined-notification-description"
                label={`Notification Description in ${language}`}
                variant="outlined"
                fullWidth
                disabled={Boolean(isPublished)}
                sx={{ mb: 2 }}
                multiline
                rows={4}
              />
              <LoadingButton
                sx={{ marginTop: "20px" }}
                variant="contained"
                type="submit"
                loading={loading}
              >
                Save
              </LoadingButton>
            </form>
          </>
        )}
      </Container>
    </Drawer>
  );
}

export default StoryDarwerLanguagesLang;
