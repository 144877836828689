import { useState } from "react";
import PropTypes from "prop-types";
import {
  NavLink as RouterLink,
  matchPath,
  useLocation,
  useNavigate,
} from "react-router-dom";
// material
import { alpha, useTheme, styled } from "@mui/material/styles";
import {
  Box,
  List,
  Collapse,
  ListItemText,
  ListItemIcon,
  ListItemButton,
} from "@mui/material";

//
import Iconify from "./Iconify";
import { Icon } from "@iconify/react";

// ----------------------------------------------------------------------

const ListItemStyle = styled((props) => (
  <ListItemButton disableGutters {...props} />
))(({ theme }) => ({
  ...theme.typography.body2,
  height: 48,
  position: "relative",
  textTransform: "capitalize",
  paddingLeft: theme.spacing(5),
  paddingRight: theme.spacing(2.5),
  color: theme.palette.text.secondary,
  "&:hover": {
    color: "#87c326",
  },
  "&:before": {
    top: 0,
    right: 0,
    width: 3,
    bottom: 0,
    content: "''",
    display: "none",
    position: "absolute",
    borderTopLeftRadius: 4,
    borderBottomLeftRadius: 4,
    backgroundColor: theme.palette.primary.main,
  },
}));

const ListItemIconStyle = styled(ListItemIcon)({
  width: 22,
  height: 22,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
});

// ----------------------------------------------------------------------

NavItem.propTypes = {
  item: PropTypes.object,
  active: PropTypes.func,
};

function NavItem({ item, active }) {
  const arrowIosDownwardFill = "eva:arrow-ios-downward-fill";
  const arrowIosForwardFill = "eva:arrow-ios-forward-fill";
  const theme = useTheme();
  // const isActiveRoot = active(item.path);
  const isActiveRoot = item.children?item.children.find(child=>active(child.path))?true:false:active(item.path)
  const { title, path, icon, dot  ,info, children } = item;
  console.log(dot,"trfyjhgfhfhj")
  const [open, setOpen] = useState(isActiveRoot);
  const navigate = useNavigate();
  const handleOpen = () => {
    setOpen((prev) => !prev);
    // if(path&&!open){
    //   navigate(path)
    // }
  };
     
  const activeRootStyle = {
    color: "#fff",
    fontWeight: "fontWeightMedium",
    borderTopLeftRadius: 34,
    borderBottomRightRadius: 34,
    bgcolor: alpha(
      theme.palette.primary.main,
      theme.palette.action.sidebarSelectedOpacity
    ),
    // '&:before': { display: 'block' },
  };
  
  const activeSubStyle = {
    color: "text.primary",
    fontWeight: "fontWeightMedium",
    "&:hover": { color: "#655FB1" },
  };

  if (children) {
    return (
      <>
        <ListItemStyle
          onClick={handleOpen}
          sx={{
            ...(isActiveRoot && activeRootStyle),
          }}
        >
          <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
          <ListItemText disableTypography primary={title} />
            {info && info}
          <Box sx={{ width: 16, height: 16, ml: 1 }}>
            <Iconify icon={open ? arrowIosDownwardFill : arrowIosForwardFill} />
          </Box>
        </ListItemStyle>

        <Collapse in={open} timeout="auto" unmountOnExit>
          <List component="div" disablePadding>
            {children.map((item) => {
              const { title, path } = item;
              const isActiveSub = active(path);

              return (
                <ListItemStyle
                  key={title}
                  component={RouterLink}
                  to={path}
                  sx={{
                    ...(isActiveSub && activeSubStyle),
                  }}
                > 
                  <ListItemIconStyle>
                    <Box
                      component="span"
                      sx={{
                        width: 4,
                        height: 4,
                        display: "flex",
                        borderRadius: "50%",
                        alignItems: "center",
                        justifyContent: "center",
                        bgcolor: "text.disabled",
                        transition: (theme) =>
                          theme.transitions.create("transform"),
                        ...(isActiveSub && {
                          transform: "scale(2)",
                          bgcolor: "primary.main",
                        }),
                      }}
                    />
                  </ListItemIconStyle>
                  <ListItemText disableTypography primary={title} />
                </ListItemStyle>
              );
            })}
          </List>
        </Collapse>
      </>
    );
  }

  return (
    <ListItemStyle
      component={RouterLink}
      to={path}
      sx={{
        ...(isActiveRoot && activeRootStyle),
      }}
    >
      <ListItemIconStyle>{icon && icon}</ListItemIconStyle>
      <ListItemText disableTypography primary={title} />
      {dot && <Icon style={{color:'#87c326'}}  icon="fluent:circle-32-filled" />}
      {info && info}
    </ListItemStyle>
  );
}

NavSection.propTypes = {
  navConfig: PropTypes.array,
};
 
export default function NavSection({ navConfig, ...other }) {
  const { pathname } = useLocation();

  const match = (path) =>
    path ? !!matchPath({ path, end: false }, pathname) : false;

  return (
    <Box {...other}>
      <List
        disablePadding
      >
        {navConfig().map((item) => (
          <NavItem key={item.title} item={item} active={match} />
        ))}
      </List>
    </Box>
  );
}
