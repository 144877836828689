import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import { useSnackbar } from "notistack";
import { getCategoryDetail, editCategory } from "src/DAL/story_category";
import { uploadImage, upload_image_api } from "src/DAL/uploadFiles";
import { supportedLanguages } from "src/constants";
import { s3baseUrl } from "src/config/config";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  CircularProgress,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { toInteger } from "lodash";
function EditCategoryDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editCategoryId,
  showEditedDataWithoutApi,
  categories,
  setCategories,
  index,
  tracksCategories,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [name, setName] = useState();
  const [image, setImage] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState(1);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [oldImage, setOldImage] = useState();
  const [selectedLanguages, setSelectedLanguages] = useState([]);

  useEffect(() => {
    async function func() {
      try {
        const res = await getCategoryDetail(editCategoryId);
        if (res.code === 200) {
          console.log(res);
          setName(res.story_category.title);
          setStatus(res.story_category.status);
          setImagePreview(s3baseUrl + res.story_category.image);
          setOldImage(res.story_category.image);
          setData(true);
          if (res.story_category.languages?.length > 0) {
            setSelectedLanguages(res.story_category.languages);
          } else {
            setSelectedLanguages([]);
          }
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (openEditDrawer) {
      func();
    }
  }, [openEditDrawer]);

  //<==========Function that will edit the category=========>
  const editCategoryApi = async (obj) => {
    try {
      const res = await editCategory(editCategoryId, obj);
      if (res.code === 200) {
        tracksCategories();
        // showEditedDataWithoutApi(
        //   index,
        //   res.category,
        //   categories,
        //   setCategories
        // );
        enqueueSnackbar(res.message, { variant: "success" });
        setOpenEditDrawer(false);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not edit the Category, Please try again", {
        variant: "error",
      });
    }
  };
  //======================================================================

  const handleSubmit = async (e) => {
    setLoading(true);
    e.preventDefault();
    setLoading(true);
    if (selectedImage) {
      const formData = new FormData();
      formData.append("image", selectedImage);
      try {
        const response = await upload_image_api(formData);
        if (response.code === 200) {
          const newCategory = {
            title: name,
            image: response.path,
            status,
            languages: selectedLanguages,
          };
          await editCategoryApi(newCategory);
        } else {
          enqueueSnackbar(response.message, {
            variant: "error",
          });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Something went wrong, Please try again", {
          variant: "error",
        });
      }
    } else {
      await editCategoryApi({
        title: name,
        image: oldImage,
        status,
        languages: selectedLanguages,
      });
    }
    setLoading(false);
  };

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => {
        setOpenEditDrawer(false);
        setData(false);
      }}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Edit Category
        </Typography>
        <Alert severity="info" color="primary" sx={{ mb: 3 }}>
          <small>Default Language is English</small>
        </Alert>

        {data && (
          <form>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Languages</InputLabel>
              <Select
                required
                label="Languages"
                id="demo-simple-select"
                multiple
                value={selectedLanguages}
                onChange={(e) => setSelectedLanguages(e.target.value)}
                //MenuProps={MenuProps}
              >
                {supportedLanguages.map((name) => {
                  return (
                    <MenuItem className="text-capitalize" value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Inactive</MenuItem>
              </Select>
            </FormControl>
            {/* <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Order"
                // type="number"
                value={order}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
                onChange={(e) => {
                  if (e.target.value.match(/^$|[0-9]+$/))
                    setOrder(toInteger(e.target.value));
                }}
              />
            </FormControl> */}
            <Grid container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    //   sx={{ width: "50%" }}
                    component="label"
                  >
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                      </>
                    )}
                    <input
                      hidden
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
              </Grid>
              {imagePreview && (
                <Grid item xs={4}>
                  <img
                    src={imagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
            <FormControl>
              <LoadingButton
                loading={loading}
                onClick={handleSubmit}
                type="submit"
                variant="contained"
              >
                Save
              </LoadingButton>
            </FormControl>
          </form>
        )}
        {!data && (
          <CircularProgress
            sx={{ display: "block", mx: "auto", my: "190px" }}
          />
        )}
      </Container>
    </Drawer>
  );
}

export default EditCategoryDrawer;
