import React from "react";
import { TextField, InputAdornment, IconButton } from "@mui/material";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import DatePicker from "react-datepicker";
const DatePickerField = ({ label, value, onChange, disabled }) => {
  const CustomInput = React.forwardRef((props, ref) => (
    <TextField
      fullWidth
      required
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton>
              <CalendarMonthIcon />
            </IconButton>
          </InputAdornment>
        ),
      }}
      {...props}
      inputRef={ref}
    />
  ));
  const filterPassedTime = (time) => {
    const currentDate = new Date();
    const selectedDate = new Date(time);

    return currentDate.getTime() < selectedDate.getTime();
  };
  return (
    <DatePicker
      selected={value}
      onChange={onChange}
      minDate={new Date()}
      filterTime={filterPassedTime}
      showTimeSelect
      timeIntervals={1}
      disabled={disabled}
      dateFormat="MMMM d, yyyy h:mm aa"
      customInput={<CustomInput label={label} />}
    />
  );
};

export default DatePickerField;
