import { invokeApi } from "src/utils";
export const getCategories = async (page, limit, data) => {
  console.log(localStorage.getItem("token"), "token in categories////");

  const requestObj = {
    path: `api/story_category/list_story_category?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getActiveCategories = async (page, limit, data) => {
  const requestObj = {
    path: `api/story_category/active_story_category?page=${page}&limit=${limit}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getCategoryDetail = async (id) => {
  const requestObj = {
    path: `api/story_category/detail_story_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addCategory = async (data) => {
  const requestObj = {
    path: `api/story_category/add_story_category`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editCategory = async (_id, data) => {
  const requestObj = {
    path: `api/story_category/update_story_category/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deletStoryeCategory = async (_id) => {
  const requestObj = {
    path: `api/story_category/delete_story_category/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getAllCategories = async () => {
  const requestObj = {
    path: `api/story_category/get_all_categories`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getTranslateRecords = async (story_category_id, language) => {
  const requestObj = {
    path: `api/story_category/get_translated_record/${story_category_id}?language=${language}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const PUTStoryLanguage = async (story_category_id, requestBody) => {
  console.log(requestBody, "request body in put story....");
  const requestObj = {
    path: `api/story_category/translate_story_category/${story_category_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: requestBody,
  };
  return invokeApi(requestObj);
};
