import React, { useState } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { uploadImage } from "src/DAL/uploadFiles";
import { addTrack } from "src/DAL/tracks";
import { useSnackbar } from "notistack";
import { Iconify } from "src/components";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
} from "@mui/material";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import { addDashboardImageAndCategory } from "src/DAL/dashboardImage";
import { getCategories } from "src/DAL/category";
function AddDashboardImage({
  openAddDrawer,
  setOpenAddDrawer,
  functionGetDashboardImage,
  dashboadImagesAndCategories
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [apiImagePath, setApiImagePath] = useState({});
  const [selectedImage, setSelectedImage] = useState();
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [trackCategory, setTrackCategory] = useState();
  const [selectedCategory, setSelectedCategory] = useState([]);

  //=============== The function that submits the form======================
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategory.length !== 4) {
      enqueueSnackbar("Please select 4 categories", { variant: "error" });
      return;
    }
    const formData = new FormData();
    formData.append("image", selectedImage);
    formData.append("module", "track");
    const resultUpload = await uploadImage(formData);
    if (resultUpload.code == 200) {
      setApiImagePath(resultUpload.paths);
        const reqObj = {
          images: resultUpload.paths,
          categories: selectedCategory,
        };
        const result = await addDashboardImageAndCategory(reqObj);
        if (result.code == 200) {
          functionGetDashboardImage()
          setOpenAddDrawer(false)
          enqueueSnackbar(result.message,{variant:"success"})
        } else {
          enqueueSnackbar(result.message,{variant:"error"})
          console.log(`Error: ${result.message}`);
        }
    }
  };
  //==========================================================================

  // function that will hit at closing the drawer
  const handleClose = () => {
    setOpenAddDrawer(false);
    setSelectedImage();
    setLoading(false);
    setImagePreview();
  };
  //==========================================================================
  const getTrackCategories = async () => {
    const result = await getCategories();
    if (result.code == 200) {
      console.log("success");
      setTrackCategory(result.category);
    } else {
      console.log(`Error: ${result.message}`);
    }
  };
  // function that will preview the image before uploading

  const handleChange = (e) => {
    if (e.target.value.length <= 4) setSelectedCategory(e.target.value);
  };

  useEffect(() => {
    getTrackCategories();
  }, []);
  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));
      console.log(imagePreview);
    }
  }, [selectedImage]);

  useEffect(() => {
    getTrackCategories();
    const arr = dashboadImagesAndCategories?.categories.map((val) => {
      return val._id;
    });
    setSelectedCategory([...arr]);
  }, [openAddDrawer]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h5" sx={{ mt: -5, mb: 5 }}>
          Add Image & Categories
        </Typography>
        <form onSubmit={handleSubmit}>
          <Grid container spacing={2} className="mb-2">
            <Grid item xs={imagePreview ? 8 : 12}>
              <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                <Button
                  variant="contained"
                  //   sx={{ width: "50%" }}
                  component="label"
                >
                  {!selectedImage && (
                    <>
                      Select Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  {selectedImage && (
                    <>
                      <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                    </>
                  )}
                  <input
                    hidden
                    name="image"
                    accept="image/*"
                    type="file"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                </Button>
              </FormControl>
            </Grid>
            {imagePreview && (
              <Grid item xs={4}>
                <img src={imagePreview} alt="image" width="100" height="auto" />
              </Grid>
            )}
          </Grid>
          <Grid item xs={12}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">
                Select Categories
              </InputLabel>
              <Select
                multiple
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                value={selectedCategory}
                label="Select Categories"
                onChange={handleChange}
              >
                {trackCategory &&
                  trackCategory.map((val) => {
                    return <MenuItem value={val._id}>{val.name}</MenuItem>;
                  })}
              </Select>
            </FormControl>
          </Grid>
          <LoadingButton
            className="mt-3"
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddDashboardImage;
