import React, { useEffect, useState, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useSnackbar } from "notistack";
import Loader from "../../components/Loader/Loader";
import {
  Avatar,
  Button,
  ButtonGroup,
  Divider,
  IconButton,
  Input,
  TextField,
  Typography,
  Stack,
  Chip,
  Tooltip,
} from "@mui/material";
import Label from "../../components/Label";
import { s3baseUrl } from "src/config/config";
import moment from "moment";
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  _add_support_ticket_comment,
  closeSupportTicket,
  countSupport_ticket,
  deleteSupportTicket,
  detailSupport_ticket,
  markReportUs_asRead,
} from "../../DAL/supportTicket";
import CustomPopover from "src/components/loaders/CustomPopover";
import { Iconify } from "src/components";
import { uploadImage } from "src/DAL/uploadFiles";

function SupportTicketDetail() {
  const { id } = useParams();
  const navigate = useNavigate();

  const { enqueueSnackbar } = useSnackbar();

  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [isLoadingSend, setIsLoadingSend] = useState(false);
  const [ticketData, setTicketData] = useState("");
  const [comments, setComments] = useState([]);
  const [selectedComment, setSelectedComment] = useState("");
  const [openDelete, setOpenDelete] = useState(false);
  const [Templates, setTemplates] = useState([[], []]);
  const [openComplete, setOpenComplete] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [inputs, setInputs] = useState({
    message: "",
    image: [],
  });

  // const fileInputRef = useRef();

  const [openDelete_Ticket, setopenDelete_Ticket] = useState(false);
  const [openComplete_Ticket, setopenComplete_Ticket] = useState(false);
  const [images, setImages] = useState([]);
  const [previewImages, setPreviewImages] = useState([]);

  const handleChange = (e) => {
    console.log(e.target.type, "e.target.type ");
    if (e.target.type === "file") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.files[0],
      });
      return;
    }

    if (e.target.type === "checkbox") {
      setInputs({
        ...inputs,
        [e.target.name]: e.target.checked,
      });
      return;
    }

    setInputs({
      ...inputs,
      [e.target.name]: e.target.value,
    });
  };

  const handleClickRemove = (index) => {
    // console.log("image", image);
    // let newImages = images.filter((img) => img !== image);
    let newImages = [...images];
    let newPreviews = [...previewImages];
    newImages.splice(index, 1);
    newPreviews.splice(index, 1);
    // let newImages = images.splice(index,1)
    // let newPreviews = previewImages.splice(index,1)
    setImages(newImages);
    setPreviewImages(newPreviews);
  };

  const handleDeleteClick = (row) => {
    setSelectedComment(row);
    setOpenDelete(true);
  };

  const handleDeleteComment = async () => {
    try {
      const response = await deleteSupportTicket(selectedComment._id);
      if (response.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        setOpenDelete(false);
        fetchDetails();
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MENU_OPTIONS = [
    {
      label: "Delete",
      icon: "ant-design:delete-twotone",
      handleClick: handleDeleteClick,
    },
  ];

  const handleDeleteSelected = async (row) => {
    console.log(row, "row");
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleCompleteSelected = async (row) => {
    console.log(row, "row>>>>>>>>>>>>>>>>>>>>>>>>>>>");
    setSelectedRow(row);
    setOpenComplete(true);
  };

  const handleDelete = async () => {
    console.log(selectedRow, "id");
    setIsLoadingBtn(true);
    try {
      const response = await deleteSupportTicket(selectedRow._id);
      if (response.code === 200) {
        // hideDeletedDataWithoutApi(index, supportTicketList, setSupportTicketList);
        // setCount(count - 1);
        setIsLoadingBtn(false);
        enqueueSnackbar(response.message, { variant: "success" });
        setOpenDelete(false);
        setIsLoadingBtn(false);
        navigate(-1);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompleteClick = async () => {
    console.log(selectedRow, "id");
    try {
      const response = await closeSupportTicket(selectedRow._id);
      console.log(response, "response");
      if (response.code == 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        fetchDetails();
        setOpenComplete(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const MENU_OPTIONS_Ticket = (data) => {
    console.log(data, "data");
    if (data.status === "open") {
      return [
        {
          label: "Close Feedback",
          icon: "carbon:task-complete",
          handleClick: handleCompleteSelected,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleDeleteSelected,
        },
      ];
    }

    return [
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDeleteSelected,
      },
    ];
  };

  const handleUploadImage = async (e) => {
    var files = e.target.files;
    if (files.length + images.length > 5) {
      // enqueueSnackbar(`You can only add ${5-images.length} images`,{variant:"error"})
      enqueueSnackbar(`You can upload maximum five images.`, {
        variant: "error",
      });
      return;
    }

    let img = [];
    for (let i = 0; i < Math.min(files.length, 5); i++) {
      var file = files[i];
      console.log(file, "ascsacgasucasukc");
      img.push(file);
    }
    setPreviewImages((prevImages) => [
      ...prevImages,
      ...img.map((file) => get_local_preview(file)),
    ]);
    setImages((prevImages) => [...prevImages, ...img]);

    // fileInputRef.current.value = null;
    e.target.value = null;

    // setPreviewImages((prev) => [...prev, get_local_preview(files)]);
    // setPreviewImages(img.map(file=>{return get_local_preview(file)}));
    // setImages(img);

    // console.log("filesss", files);
    // var formData = new FormData();
    // formData.append("image", files);
    // formData.append("module", "category");

    // const image_resp = await uploadImage(formData);
    // console.log("image_resp", image_resp);
    // if (image_resp.code == 200) {
    //   setImages([...images, image_resp.paths]);
    // } else {
    //   enqueueSnackbar(image_resp.message, { variant: "error" });
    // }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    //var formData = new FormData();
    //formData.append("image", images);
    //console.log(images,"csachaskuchsakcjs")
    var formData = new FormData();
    images.forEach((imag) => {
      console.log(imag, "casijasiocjasicl");
      formData.append("image", imag);
    });
    formData.append("text", inputs.message);
    //console.log('formdataaaaaaa ', [...formData]);
    setIsLoadingSend(true);
    const resp = await _add_support_ticket_comment(formData, id);
    console.log("resp", resp);
    if (resp.code === 200) {
      setIsLoadingSend(false);
      setInputs({
        message: "",
        image: [],
      });
      setImages([]);
      setPreviewImages([]);
      enqueueSnackbar(resp.message, { variant: "success" });
      fetchDetails();
      CountDetails();
    } else {
      setIsLoadingSend(false);
      enqueueSnackbar(resp.message, { variant: "error" });
    }
    // else {
    //   setIsLoadingSend(false);
    //   enqueueSnackbar(resp.message, { variant: "error" });
    // }
  };

  const fetchDetails = async () => {
    const resp = await detailSupport_ticket(id);
    console.log("resp_detail_feedback", resp);
    if (resp.code === 200) {
      setTicketData(resp.report_us);
      setComments(resp.report_us.messages);
      // setComments(resp.report_us.images);
      setIsLoading(false);
    } else if (resp.code === 400) {
      navigate("/support_ticket");
    } else {
      enqueueSnackbar(resp.message, { variant: "error" });
    }
  };

  const CountDetails = async () => {
    const count_resp = await countSupport_ticket(id);
    console.log("resp_count_detail", count_resp);
    if (count_resp.code === 200) {
    } else {
      enqueueSnackbar(count_resp.message, { variant: "error" });
    }
  };

  const mark_report_asRead = async () => {
    const count_respRead = await markReportUs_asRead(id);
    console.log("resp_mark_as_read", count_respRead);
    if (count_respRead.code === 200) {
    } else {
      enqueueSnackbar(count_respRead.message, { variant: "error" });
    }
  };

  useEffect(() => {
    if (ticketData) {
      let name = [];
      name.push(ticketData.user_id);
      console.log("User Detail", ticketData);
      setTemplates([
        ["Hi User", "Hi " + ticketData.user_id.name],
        ["Greetings", "Thanks for reaching out to our support team. "],
        ["Regards", "Regards\nDynamite Digital Support Team "],
        ["Ending Signature", "Regards Dynamite Digital Support Team "],
        [
          "Thanks & Reagards",
          "Thanks & Regards\nDynamite Digital Support Team ",
        ],
        ["Feedback", "Please Let us know if this helps you "],
        [
          "Reminder",
          "Hi, " +
            ticketData.user_id.name +
            " we hope your issue has been resolved kindly give your feedback\nRegards Dynamite Digital Support Team ",
        ],
      ]);
    }
  }, [ticketData]);

  useEffect(() => {
    fetchDetails();
    CountDetails();
    mark_report_asRead();
  }, []);

  if (isLoading) {
    return <Loader />;
  }

  const CheckActivityTime = (last_action_date) => {
    var No_Response_Threshold = new Date();
    No_Response_Threshold.setDate(No_Response_Threshold.getDate() - 7);
    var last_action = new Date(last_action_date);

    if (
      No_Response_Threshold.getTime() >= last_action.getTime(last_action_date)
    ) {
      return true;
    } else {
      return false;
    }
  };

  const get_local_preview = (file) => {
    if (!file) {
      return "";
    }
    if (typeof file === "string") {
      return file;
    }
    return URL.createObjectURL(file);
  };

  return (
    <>
      <div className="container">
        <div className="row mt-2">
          <div
            className="col-12 mb-2"
            style={{
              marginLeft: "-1.3%",
            }}
          >
            <IconButton
              onClick={() => {
                navigate(-1);
              }}
              aria-label="delete"
              size="large"
              style={{ fontSize: "30px" }}
            >
              {/* <Icon icon="solar:arrow-left-outline" /> */}
              <Iconify icon="solar:arrow-left-outline" />
            </IconButton>
          </div>

          <div className="col-12 d-flex justify-content-between ">
            <div className="d-flex justify-content-center align-items-center text-center">
              <b className="me-2">Title:</b>
              {ticketData.subject}
            </div>

            <Typography className="me-5" variant="h6" gutterBottom>
              {ticketData.user_id.name}
            </Typography>

            <div className="d-flex justify-content-between">
              {!CheckActivityTime(ticketData.last_action_date) &&
                ticketData.status === "open" && (
                  <Label style={{ width: 70 }} variant="ghost" color="info">
                    OPEN
                  </Label>
                )}

              {!CheckActivityTime(ticketData.last_action_date) &&
                ticketData.status === "closed" && (
                  <Label style={{ width: 70 }} variant="ghost" color="error">
                    CLOSED
                  </Label>
                )}
              {CheckActivityTime(ticketData.last_action_date) &&
                ticketData.ticket_status === "open" && (
                  <Label style={{ width: 110 }} variant="ghost" color="error">
                    Not Responding
                  </Label>
                )}
              <div className="m-0">
                <CustomPopover
                  data={ticketData}
                  menu={MENU_OPTIONS_Ticket(ticketData)}
                />
              </div>
            </div>
          </div>

          <div className="d-flex flex-column">
            <Typography
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              gutterBottom
            >
              <b>Email: </b>
              {ticketData.user_id.email}
            </Typography>
            <Typography gutterBottom variant="p" className="d-flex">
              <b className="me-2 ">Date:</b>
              <Label>{moment(ticketData.createdAt).format("LLL")}</Label>
            </Typography>

            <Typography
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              gutterBottom
            >
              <b>Description: </b>
              {ticketData.description}
            </Typography>
            <Typography
              variant="p"
              style={{ whiteSpace: "pre-wrap" }}
              gutterBottom
            >
              <b className="me-2">Type:</b>
              <span className="me-3"> {ticketData.topic}</span>
              {ticketData.topic !== "general" && (
                <span
                  onClick={() => {
                    navigator.clipboard.writeText(ticketData.action_id._id);
                    const firstLetter = ticketData.topic
                      .charAt(0)
                      .toUpperCase();
                    const topic = firstLetter + ticketData.topic.slice(1);
                    enqueueSnackbar(
                      `
                      ${topic} Id copied to clipboard
                      `,
                      {
                        variant: "success",
                      }
                    );
                  }}
                  style={{
                    fontSize: "12px",
                    cursor: "pointer",
                    color: "#655FB1",
                    fontWeight: "600",
                    textAlign: "center",
                    fontStyle: "italic",
                    textDecoration: "underline",
                  }}
                  disabled={ticketData.topic === "general"}
                >
                  {`Copy ${ticketData.topic} id`}
                  <Iconify
                    className="ms-2"
                    sx={{ marginBottom: "3px" }}
                    icon="carbon:copy"
                  />
                </span>
              )}
            </Typography>
            <div className="d-flex justify-content-between">
              <Typography
                variant="p"
                style={{ whiteSpace: "pre-wrap" }}
                gutterBottom
              >
                <b className="me-2">Platform:</b>
                {ticketData?.platform ? ticketData?.platform : "N/A"}
              </Typography>
              <Typography
                variant="p"
                style={{ whiteSpace: "pre-wrap" }}
                gutterBottom
              >
                <b className="me-2">OS Version:</b>
                {ticketData?.phone_os_version
                  ? ticketData?.phone_os_version
                  : "N/A"}
              </Typography>
              <Typography
                variant="p"
                style={{ whiteSpace: "pre-wrap" }}
                gutterBottom
              >
                <b className="me-2">Modal:</b>
                {ticketData?.phone_brand ? ticketData?.phone_brand : "N/A"}
              </Typography>
              <Typography
                variant="p"
                style={{ whiteSpace: "pre-wrap" }}
                gutterBottom
              >
                <b className="me-2"> Application Version:</b>
                {ticketData?.app_version ? ticketData?.app_version : "N/A"}
              </Typography>
            </div>
          </div>

          <div className="col-12 mb-3">
            {ticketData.images.map((image) => {
              return (
                <a href={s3baseUrl + image.large} target="_blank">
                  <img
                    width="100"
                    height="100"
                    className="me-2 ms-2"
                    src={s3baseUrl + image.large}
                    alt=""
                  />
                </a>
              );
            })}
          </div>

          {comments.map((comment) => (
            <div className="col-12 mt-2">
              <div className="support-ticket-wrapper border-top">
                <div className="d-flex">
                  <div className="support-profile me-3 mt-2">
                    <Avatar
                      style={{ width: 50, height: 50 }}
                      src={s3baseUrl + comment.user_id?.profile_image}
                      alt={comment.user_id?.name ?? "Admin"}
                    />
                  </div>
                  <div className="w-100">
                    <div className="mt-2">
                      <Typography
                        className="mb-0 text-capitalize"
                        variant="subtitle1"
                        gutterBottom
                      >
                        {comment.user_id?.name ?? "Admin"}
                      </Typography>
                      {/* {comment.user?.type === "0" && ( */}
                      {/* <div className="float-end">
                        <CustomPopover data={comment} menu={MENU_OPTIONS} />
                      </div> */}
                      {/* )} */}
                      <Typography
                        variant="body2"
                        sx={{ color: "text.secondary" }}
                        noWrap
                      >
                        {moment(comment.date_time).fromNow()}
                      </Typography>
                    </div>
                    <div className="mt-1">
                      <Typography
                        style={{ whiteSpace: "pre-wrap" }}
                        variant="p"
                        gutterBottom
                      >
                        {comment.text}
                      </Typography>
                    </div>
                    {comment?.user_id?._id === ticketData?.user_id?._id ? (
                      <div className="d-flex justify-content-between mt-2">
                        <Typography
                          variant="subtitle1"
                          style={{ whiteSpace: "pre-wrap" }}
                          gutterBottom
                        >
                          <b className="me-2">Platform:</b>
                          {comment.platform ? comment.platform : "N/A"}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ whiteSpace: "pre-wrap" }}
                          gutterBottom
                        >
                          <b className="me-2">OS Version:</b>
                          {comment.phone_os_version
                            ? comment.phone_os_version
                            : "N/A"}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ whiteSpace: "pre-wrap" }}
                          gutterBottom
                        >
                          <b className="me-2">Modal:</b>
                          {comment.phone_brand ? comment.phone_brand : "N/A"}
                        </Typography>
                        <Typography
                          variant="subtitle1"
                          style={{ whiteSpace: "pre-wrap" }}
                          gutterBottom
                        >
                          <b className="me-2"> Application Version:</b>
                          {comment.app_version ? comment.app_version : "N/A"}
                        </Typography>
                      </div>
                    ) : (
                      ""
                    )}
                    <div className="mt-1">
                      {comment.images.map((image) => {
                        return (
                          <a
                            className="me-3"
                            href={s3baseUrl + image.large}
                            target="_blank"
                          >
                            <img
                              style={{ marginBottom: "11px" }}
                              height="70"
                              width="70"
                              src={s3baseUrl + image.large}
                              alt=""
                            />
                          </a>
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}

          {ticketData.status === "open" && (
            <form onSubmit={handleSubmit}>
              <div className="col-12 mt-2">
                <TextField
                  fullWidth
                  label="Message"
                  placeholder="Please add your details here"
                  required
                  name="message"
                  multiline
                  rows={5}
                  value={inputs.message}
                  onChange={handleChange}
                />
              </div>

              <div className="col-12 mt-3 d-flex justify-content-between align-items-center">
                <div className="d-flex align-items-center">
                  <label htmlFor="upload-photo">
                    <input
                      // ref={fileInputRef}
                      style={{ display: "none" }}
                      id="upload-photo"
                      name="image"
                      type="file"
                      multiple
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      disabled={previewImages.length >= 5}
                      // onChange={
                      //   previewImages.length < 5 ?
                      //   handleUploadImage : ()=> {
                      //   enqueueSnackbar('You can upload maximum 5 images', {variant: 'error'})
                      //   }
                      //   }
                      onChange={handleUploadImage}
                    />
                    <Button
                      // color="secondary"
                      // variant="contained"
                      // component="span"
                      aria-label="upload picture"
                      component="span"
                      className="upload-button"
                      fullWidth
                      disabled={previewImages.length >= 5}
                    >
                      Upload Image
                    </Button>
                  </label>
                </div>
                <Button
                  size="large"
                  type="submit"
                  variant="contained"
                  // loading={isLoadingSend}
                  disabled={isLoadingSend}
                  className="mt-1"
                >
                  {isLoadingSend ? "Sending..." : "Send"}
                </Button>
              </div>
              <div className="col-12 mt-3 d-flex align-items-center">
                {previewImages.map((image, index) => (
                  <div
                    key={index}
                    style={{
                      position: "relative",
                      // display: "inline-block",
                      marginRight: "20px",
                    }}
                  >
                    <img
                      src={image}
                      height={"100px"}
                      width={"100px"}
                      style={{ objectFit: "cover" }}
                    />
                    <div
                      onClick={() => handleClickRemove(index)}
                      style={{
                        position: "absolute",
                        top: "5%",
                        right: "5%",
                      }}
                    >
                      <Iconify
                        sx={{
                          cursor: "pointer",
                          fontSize: "25px",
                          color: "#bf261b",
                          // backgroundColor:'rgba(211, 211, 211, 0.8)',
                          backgroundColor: "white",
                          borderRadius: "50%",
                          padding: "2px",
                        }}
                        icon="charm:cross"
                      />
                    </div>
                  </div>
                ))}
              </div>
              <hr></hr>
              <Stack direction="row" spacing={1} overflow="auto">
                {Templates.map((template) => (
                  <Chip
                    onClick={() => {
                      setInputs({ message: inputs.message + template[1] });
                    }}
                    label={template[0]}
                  />
                ))}
              </Stack>
              <hr></hr>
            </form>
          )}
        </div>
      </div>

      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        title="Feedback"
        handleAgree={handleDelete}
      />

      <CustomConfirmation
        open={openComplete}
        setOpen={setOpenComplete}
        message="Are you sure you want to close this Feedback?"
        handleAgree={handleCompleteClick}
      />

      {/* <CustomConfirmation
        open={openComplete_Ticket}
        setOpen={setopenComplete_Ticket}
        title={"Are you sure you want to delete this comment?"}
        handleAgree={handleCompleteClick}
      /> */}
    </>
  );
}

export default SupportTicketDetail;
