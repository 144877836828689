import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { uploadImage, uploadAudio } from "src/DAL/uploadFiles";
import { editTrack, getTrackDetail } from "src/DAL/tracks";
import LoadingButton from "@mui/lab/LoadingButton";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { s3baseUrl } from "src/config/config";
import { Iconify } from "src/components";
import DatePickerField from "src/components/Datepicker";
import { supportedLanguages } from "src/constants";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Stack,
  ToggleButton,
  ToggleButtonGroup,
  Typography,
  Grid,
  CircularProgress,
  Alert,
  AlertTitle,
} from "@mui/material";
import { toInteger } from "lodash";
import { useAppContext } from "src/hooks";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
      "&:active": { bgColor: "red" },
    },
  },
};
function EditTrackDrawer({
  openEditDrawer,
  setOpenEditDrawer,
  editTrackId,
  categories,
  showEditedDataWithoutApi,
  index,
  tracks,
  setTracks,
  funcTracks,
}) {
  const { checked } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const [data, setData] = useState(false);
  const [name, setName] = useState();
  const [author, setAuthor] = useState();
  const [description, setDescription] = useState();
  const [loading, setLoading] = useState(false);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [imagePreview, setImagePreview] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const [status, setStatus] = useState("");
  const [order, setOrder] = useState();
  const [trackDetailAudio, setTrackDetailAudio] = useState();
  const [trackDetailAudioDuration, setTrackDetailAudioDuration] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [notificationDate, setNotificationDate] = useState();
  const [segment, setSegment] = useState("day");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    async function func() {
      try {
        const res = await getTrackDetail(editTrackId);
        if (res.code === 200) {
          setName(res.track.name);
          setStatus(res.track.status);
          setAuthor(res.track.author);
          setSegment(res.track.day_segment);
          setDescription(res.track.description);
          setTrackDetailAudio(res.track.audio);
          setTrackDetailAudioDuration(res.track.duration);
          setIsPaid(res.track.is_locked);
          setOrder(res.track.order);
          setImagePreview(s3baseUrl + res.track.images.small);
          setNotificationTitle(res.track.notification_title);
          setNotificationDescription(res.track.notification_description);
          setIsPublished(res.track.is_published);
          if (res.track.languages?.length > 0) {
            setSelectedLanguages(res.track.languages);
          }
          // setNotificationDate(new Date(res.track.date))
          let tempCategories = [];
          res.track.category_id.map((category) => {
            if (category._id)
              tempCategories = [...tempCategories, category._id._id];
          });
          setSelectedCategories(tempCategories);
          // console.log('selected '+res.track.category_id.name);
          setData(true);
        } else {
          enqueueSnackbar(res.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
      }
    }
    if (editTrackId) func();
  }, [openEditDrawer]);

  //<==========Function that will edit the track=========>

  const editTrackApi = async (obj) => {
    try {
      const res = await editTrack(editTrackId, obj);
      if (res.code === 200) {
        console.log(res);
        // showEditedDataWithoutApi(index, res.track, tracks, setTracks);
        funcTracks();
        enqueueSnackbar(res.message, { variant: "success" });
        setOpenEditDrawer(false);
      } else {
        enqueueSnackbar(res.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      enqueueSnackbar("Could not edit the Track, Please try again", {
        variant: "error",
      });
    }
  };
  //======================================================================

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategories.length > 0) {
      setLoading(true);
      let newCat,
        newSelectedCategories = [];
      selectedCategories.map((category) => {
        newCat = { _id: category };
        // console.log(newCat);
        newSelectedCategories = [...newSelectedCategories, newCat];
      });
      if (selectedImage && selectedAudio) {
        const formDataImage = new FormData();
        formDataImage.append("image", selectedImage);
        formDataImage.append("module", "track");
        try {
          const responseImage = await uploadImage(formDataImage);
          if (responseImage.code === 200) {
            const formDataAudio = new FormData();
            formDataAudio.append("audio", selectedAudio);
            try {
              const responseAudio = await uploadAudio(formDataAudio);
              if (responseAudio.code === 200) {
                try {
                  await editTrackApi({
                    name,
                    status,
                    description,
                    day_segment: segment,
                    languages: selectedLanguages,
                    category_id: newSelectedCategories,
                    audio: responseAudio.file.path,
                    duration: responseAudio.file.duration,
                    images: responseImage.paths,
                    is_locked: isPaid,
                    order,
                    // date: notificationDate,
                    notification_title: notificationTitle,
                    notification_description: notificationDescription,
                    // is_published: isPublished,
                  });
                } catch (error) {
                  console.log(error);
                }
              } else {
                enqueueSnackbar(responseAudio.message, { variant: "error" });
              }
            } catch (error) {
              console.log(error);
            }
          } else {
            enqueueSnackbar(responseImage.message, { vaiant: "error" });
          }
        } catch (error) {
          console.log(error);
        }
      } else if (selectedImage && !selectedAudio) {
        const formDataImage = new FormData();
        formDataImage.append("image", selectedImage);
        formDataImage.append("module", "track");
        try {
          const responseImage = await uploadImage(formDataImage);
          if (responseImage.code === 200) {
            try {
              await editTrackApi({
                name,
                status,
                author,
                description,
                category_id: newSelectedCategories,
                languages: selectedLanguages,

                audio: trackDetailAudio,
                duration: trackDetailAudioDuration,
                images: responseImage.paths,
                order,
                is_locked: isPaid,
                // date: notificationDate,
                notification_title: notificationTitle,
                notification_description: notificationDescription,
                day_segment: segment,
                // is_published: isPublished,
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            enqueueSnackbar(responseImage.message, { variant: "error" });
          }
        } catch (error) {
          console.log(error);
        }
      } else if (!selectedImage && selectedAudio) {
        const formDataAudio = new FormData();
        formDataAudio.append("audio", selectedAudio);
        try {
          const responseAudio = await uploadAudio(formDataAudio);
          if (responseAudio.code === 200) {
            try {
              await editTrackApi({
                name,
                status,
                author,
                description,
                day_segment: segment,
                category_id: newSelectedCategories,
                languages: selectedLanguages,

                audio: responseAudio.file.path,
                duration: responseAudio.file.duration,
                images: {},
                order,
                is_locked: isPaid,
                // date: notificationDate,
                notification_title: notificationTitle,
                notification_description: notificationDescription,
                // is_published: isPublished,
              });
            } catch (error) {
              console.log(error);
            }
          } else {
            enqueueSnackbar(responseAudio.message, { variant: "error" });
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        try {
          await editTrackApi({
            name,
            description,
            author,
            status,
            category_id: newSelectedCategories,
            languages: selectedLanguages,

            audio: trackDetailAudio,
            duration: trackDetailAudioDuration,
            images: {},
            order,
            is_locked: isPaid,
            // date: notificationDate,
            notification_title: notificationTitle,
            notification_description: notificationDescription,
            day_segment: segment,
            // is_published: isPublished,
          });
        } catch (error) {
          console.log(error);
        }
      }
      setLoading(false);
    } else {
      enqueueSnackbar("Please select atleast 1 category", { variant: "error" });
    }
  };

  const getScheduleStatus = (date) => {
    if (date) {
      const scheduleDate = new Date(date);
      const currentDate = new Date();

      if (scheduleDate.getTime() > currentDate.getTime()) {
        return false;
      } else if (scheduleDate.getTime() <= currentDate.getTime()) {
        return true;
      }
    } else {
      return true;
    }
  };

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => {
        setOpenEditDrawer(false);
        setData();
        setImagePreview();
      }}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Edit Track
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to{" "}
              <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>
              Notifications will be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        <Alert severity="info" color="primary" sx={{ mb: 3 }}>
          <small>Default Language is English</small>
        </Alert>

        {data && (
          <form>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={name}
                onChange={(e) => setName(e.target.value)}
                id="outlined-basic"
                label="Name"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                onChange={(e) => setDescription(e.target.value)}
                required
                value={description}
                id="outlined-multiline-static"
                label="Description"
                multiline
                rows={4}
                //   variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                value={author}
                onChange={(e) => setAuthor(e.target.value)}
                id="outlined-basic"
                label="Author"
                variant="outlined"
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Categories</InputLabel>
              <Select
                required
                label="Categories"
                multiple
                value={selectedCategories}
                onChange={(e) => setSelectedCategories(e.target.value)}
                MenuProps={MenuProps}
              >
                {categories &&
                  categories.map((category) => {
                    return (
                      <MenuItem key={category._id} value={category._id}>
                        {category.name}
                      </MenuItem>
                    );
                  })}
                {!categories && "No category Found"}
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel>Languages</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                label="Languages"
                multiple
                id="demo-simple-select"
                value={selectedLanguages}
                onChange={(e) => setSelectedLanguages(e.target.value)}

                //MenuProps={MenuProps}
              >
                {supportedLanguages?.map((name) => {
                  return (
                    <MenuItem className="text-capitalize" value={name}>
                      {name}
                    </MenuItem>
                  );
                })}
              </Select>
            </FormControl>

            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Paid</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Paid"
                value={isPaid}
                onChange={(e) => setIsPaid(e.target.value)}
              >
                <MenuItem value={true}>
                  <Iconify icon="ic:outline-attach-money" color="green" /> Yes
                </MenuItem>
                <MenuItem value={false}>
                  <Iconify icon="ic:outline-money-off-csred" color="red" /> No
                </MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Type</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Segment"
                value={segment}
                onChange={(e) => setSegment(e.target.value)}
              >
                <MenuItem value="day">Morning</MenuItem>
                <MenuItem value="evening">Evening</MenuItem>
                <MenuItem value="night">Night</MenuItem>
              </Select>
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <InputLabel id="demo-simple-select-label">Status</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                label="Status"
                value={status}
                onChange={(e) => setStatus(e.target.value)}
              >
                <MenuItem value={true}>Active</MenuItem>
                <MenuItem value={false}>Non-Active</MenuItem>
              </Select>
            </FormControl>
            <FormControl sx={{ mb: 2 }} fullWidth>
              <TextField
                label="Order"
                // type="number"
                // InputProps={{ inputProps: { min: 1} }}
                value={order}
                onChange={(e) => {
                  if (e.target.value.match(/^$|[0-9]+$/))
                    setOrder(toInteger(e.target.value));
                }}
                variant="outlined"
                inputProps={{
                  maxLength: 13,
                  step: "1",
                }}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                onChange={(e) => setNotificationTitle(e.target.value)}
                id="outlined-basic"
                label="Notification Title"
                variant="outlined"
                value={notificationTitle}
                disabled={Boolean(isPublished)}
              />
            </FormControl>
            <FormControl fullWidth sx={{ mb: 2 }}>
              <TextField
                onChange={(e) => setNotificationDescription(e.target.value)}
                id="outlined-multiline-static"
                label="Notification Description"
                multiline
                rows={4}
                defaultValue=""
                value={notificationDescription}
                disabled={Boolean(isPublished)}
              />
            </FormControl>

            {/* <FormControl fullWidth sx={{ mb: 2 }}>
              <DatePickerField
                label="Notification Date *"
                value={notificationDate}
                onChange={(date) => setNotificationDate(date)}
                disabled={getScheduleStatus(notificationDate)}
              />
            </FormControl> */}

            <Grid container spacing={2}>
              <Grid item xs={imagePreview ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    //   sx={{ width: "50%" }}
                    component="label"
                  >
                    {!selectedImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                      </>
                    )}
                    <input
                      hidden
                      accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                      type="file"
                      onChange={(e) => setSelectedImage(e.target.files[0])}
                    />
                  </Button>
                </FormControl>
                <small>
                  Recommended image size (1080x1920) and file types (jpg,png)
                </small>
              </Grid>
              {imagePreview && (
                <Grid item xs={4}>
                  <img
                    src={imagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>

            <Grid direction="row" spacing={2}>
              <div className="d-flex mt-4">
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button
                    variant="contained"
                    //   sx={{ width: "50%" }}
                    component="label"
                  >
                    {!selectedAudio && (
                      <>
                        Select Track <AudioFileIcon sx={{ ml: 1 }} />
                      </>
                    )}
                    {selectedAudio && (
                      <>
                        <AudioFileIcon sx={{ mr: 1 }} /> {selectedAudio.name}
                      </>
                    )}
                    <input
                      hidden
                      accept="audio/*"
                      type="file"
                      onChange={(e) => {
                        if (e.target.files[0].size > 10485760) {
                          enqueueSnackbar(
                            "Maximum size limit for track is 10 mb",
                            {
                              variant: "error",
                            }
                          );
                          e.target.value = null;
                        } else setSelectedAudio(e.target.files[0]);
                      }}
                    />
                  </Button>
                </FormControl>
                {/* {trackDetailAudio&&
            <a style={{textDecoration:'none', color:"#87c326"}} href={s3baseUrl+trackDetailAudio} target="_blank">
            <Iconify style={{fontSize:'50px'}} icon="ic:round-audio-file"/>
            </a>
            } */}
              </div>
            </Grid>

            <div className="mt-2">
              <FormControl>
                <LoadingButton
                  loading={loading}
                  onClick={handleSubmit}
                  type="submit"
                  variant="contained"
                >
                  Save
                </LoadingButton>
              </FormControl>
            </div>
          </form>
        )}
        {!data && (
          <CircularProgress
            sx={{ display: "block", mx: "auto", my: "190px" }}
          />
        )}
      </Container>
    </Drawer>
  );
}

export default EditTrackDrawer;
