import { Navigate, useRoutes } from "react-router-dom";
// layouts
import DashboardLayout from "./layouts/dashboard/DashboardLayout";
import LogoOnlyLayout from "./layouts/LogoOnlyLayout";
//pages
import {
  AddUser,
  Dashboard,
  ForgotPassword,
  Login,
  Page404,
  Profile,
  Register,
  Settings,
  Users,
  Habit,
  Categories,
  Tracks,
  Quotes,
  AppSettings,
  PushNotifications,
  ViewSandboxUser,
  DashboardSettings,
} from "./pages";
import QuoteCategories from "./pages/quoteCategories/QuoteCategories";
import SupportTicket from "./pages/report_us/SupportTicket";
import SupportTicketDetail from "./pages/report_us/SupportTicketDetail";
import StoryCategories from "./pages/story_categories/StoryCategories";
import Story from "./pages/story/Story";

// ----------------------------------------------------------------------

export default function Router() {
  return useRoutes([
    {
      path: "/",
      element: <DashboardLayout />,
      children: [
        { path: "dashboard", element: <Dashboard /> },
        { path: "users", element: <Users /> },
        { path: "sandbox_users", element: <ViewSandboxUser /> },
        { path: "dashboard_settings", element: <DashboardSettings /> },
        // { path: 'habit', element: <Habit /> },
        { path: "track-categories", element: <Categories /> },
        { path: "stories-categories", element: <StoryCategories /> },
        // { path: 'quote-categories', element: <QuoteCategories />},
        { path: "tracks", element: <Tracks /> },
        { path: "/story", element: <Story /> },

        { path: "support_ticket", element: <SupportTicket /> },
        {
          path: "support_ticket/support_ticket_detail/:id",
          element: <SupportTicketDetail />,
        },
        // { path: 'quotes', element: <Quotes />},
        // { path: 'users/add-user', element: <AddUser /> },
        { path: "profile", element: <Profile /> },
        { path: "settings", element: <Settings /> },
        { path: "app_settings", element: <AppSettings /> },
        { path: "push_notifications", element: <PushNotifications /> },
      ],
    },
    {
      path: "/",
      element: <LogoOnlyLayout />,
      children: [
        { path: "/", element: <Navigate to="/dashboard" /> },
        { path: "login", element: <Login /> },
        { path: "register", element: <Register /> },
        { path: "forgot-password", element: <ForgotPassword /> },
        { path: "404", element: <Page404 /> },
        { path: "*", element: <Navigate to="/404" /> },
      ],
    },
    { path: "*", element: <Navigate to="/404" replace /> },
  ]);
}
