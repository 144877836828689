import Search from "antd/lib/transfer/search";
import { method } from "lodash";
import { invokeApi } from "src/utils";
export const getStories = async (page, limit, data) => {
  console.log(localStorage.getItem("token"), "token is ......");
  const requestObj = {
    path: `api/story/list_story?page=${page}&limit=${limit}`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const getCategoryDetail = async (id) => {
  const requestObj = {
    path: `api/story_category/detail_story_category/${id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const addStory = async (data) => {
  console.log(data, "story request scnz");
  const requestObj = {
    path: `api/story/add_story`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const videoUploadApi = async (data) => {
  const requestObj = {
    path: `api/app_api/upload_video`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const editStory = async (_id, data) => {
  const requestObj = {
    path: `api/story/update_story/${_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};
export const storyDetail = async (_id, data) => {
  const requestObj = {
    path: `api/story/detail_story/${_id}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const filterStory = async (data) => {
  const requestObj = {
    path: `api/story/story_filter`,
    method: "POST",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: data,
  };
  return invokeApi(requestObj);
};

export const deleteStory = async (_id) => {
  const requestObj = {
    path: `api/story/delete_story/${_id}`,
    method: "DELETE",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getAllCategories = async () => {
  const requestObj = {
    path: `api/story_category/get_all_categories`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const getStoryRecords = async (story_id, language) => {
  const requestObj = {
    path: `api/story/get_translated_record/${story_id}?language=${language}`,
    method: "GET",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
  };
  return invokeApi(requestObj);
};

export const PutStory_Languages = async (story_id, requestBody) => {
  console.log(requestBody, "request body in put story....");
  const requestObj = {
    path: `api/story/translate_story/${story_id}`,
    method: "PUT",
    headers: {
      "x-sh-auth": localStorage.getItem("token"),
    },
    postData: requestBody,
  };
  return invokeApi(requestObj);
};
