import { filter } from "lodash";
import { sentenceCase } from "change-case";
import { useState, useEffect } from "react";
import { getUsers, deleteUser, addSandboxUser } from "src/DAL/users";
import { s3baseUrl } from "src/config/config";
import { useSnackbar } from "notistack";
import { hideDeletedDataWithoutApi } from "src/DAL/updateWithoutApi";
// material
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Divider,
} from "@mui/material";
// components
import { Page, Label, Scrollbar, SearchNotFound } from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
// mock
//
import { useNavigate } from "react-router-dom";
// modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};
// ----------------------------------------------------------------------

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "email", label: "Email", alignRight: false },
  // { id: 'role', label: 'Role', alignRight: false },
  // { id: 'isVerified', label: 'Verified', alignRight: false },
  { id: "user-type", label: "User Type", alignRight: false },
  // { id: "status", label: "Status", alignRight: false },
  { id: "sandbox", label: "Sandbox", alignRight: false },
  { id: "actions", label: "Actions", alignRight: true },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === "desc"
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

export default function Users() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [users, setUsers] = useState();
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [order, setOrder] = useState("asc");

  const [selected, setSelected] = useState([]);

  const [orderBy, setOrderBy] = useState("name");

  const [filterName, setFilterName] = useState("");

  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [count, setCount] = useState(0);

  // modal
  const [open, setOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const handleOpen = (image) => {
    if (image) {
      setImagePath(image);
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);
  // -----------

  function applySortFilter(array, comparator, query) {
    if (users) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_user) =>
            _user.name.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }
  useEffect(() => {
    const func = async () => {
      // if(filterName!=='') {
      //   setPage(0);
      //   setCount(0);
      // }
      const response = await getUsers(page, rowsPerPage, filterName);
      if (response.code === 200) {
        setCount(response.count);
        setUsers(response.customer);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
      setOpenBackdrop(false);
    };
    func();
  }, [filterName, page, rowsPerPage]);

  useEffect(() => {
    setUsers();
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === "asc";
    setOrder(isAsc ? "desc" : "asc");
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = users.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];
    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }
    setSelected(newSelected);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deleteUser(id);
      if (response.code === 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        setCount(count - 1);
        hideDeletedDataWithoutApi(index, users, setUsers);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      enqueueSnackbar(error);
    }
  };
  // const emptyRows =
  //   page > 0 ? Math.max(0, (1 + page) * rowsPerPage - users.length) : 0;

  const filteredUsers = users
    ? applySortFilter(users, getComparator(order, orderBy), filterName)
    : [];

  const isUserNotFound = filteredUsers.length === 0;

  const handleNav = () => {
    navigate("/users/add-user");
  };

  // API: Add sandbox user
  const handle_add_user_to_sandbox = async (id, user_type) => {
    console.log(user_type, "user_typeuser_typeuser_type");
    if (user_type == false) {
      enqueueSnackbar("Guests are not allowed in the sandbox!", {
        variant: "error",
      });
      return;
    }
    const response = await addSandboxUser(id);
    if (response.code === 200) {
      enqueueSnackbar(response.message, { variant: "success" });
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  };
  return (
    <Page title="User">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="space-between"
          mb={4.5}
        >
          {/* <Typography variant="h4">Users</Typography> */}
          {/* <Button
            onClick={handleNav}
            variant='contained'
            startIcon={<Iconify icon='eva:plus-fill' />}
          >
            New User
          </Button> */}
        </Stack>

        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            // numSelected={selected.length}
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Users"
          />

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {users &&
                    // filteredUsers
                    users
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const {
                          _id,
                          name,
                          id = row.user_id?._id,
                          email = row.user_id?.email,
                          // status,
                          is_sandbox,
                          profile_image,
                          user_type,
                        } = row;
                        const isItemSelected = selected.indexOf(name) !== -1;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                            selected={isItemSelected}
                            aria-checked={isItemSelected}
                          >
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar
                                  alt={name}
                                  src={s3baseUrl + profile_image}
                                  onClick={() => handleOpen(profile_image)}
                                />
                              </Stack>
                            </TableCell>
                            <TableCell align="left">
                              <Typography variant="subtitle2" noWrap>
                                {name ? name : "Guest User"}
                              </Typography>
                            </TableCell>
                            {/* <TableCell align='left'>{role}</TableCell>
                          <TableCell align='left'>{isVerified ? 'Yes' : 'No'}</TableCell> */}
                            <TableCell align="left">
                              {email ? email : "Guest User"}
                            </TableCell>

                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (user_type === false && "error") || "success"
                                }
                              >
                                {user_type === true ? "Active" : "Guest"}
                              </Label>
                            </TableCell>
                            {/* <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (status === "banned" && "error") || "success"
                                }
                              >
                                {status === true ? "Active" : "Banned"}
                              </Label>
                            </TableCell> */}
                            <TableCell align="left">
                              <Typography
                                variant="subtitle2"
                                align="center"
                                noWrap
                              >
                                <Label
                                  variant="outlined"
                                  color={is_sandbox ? "success" : "error"}
                                >
                                  {is_sandbox.toString()}
                                </Label>
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                id={id}
                                user_type={user_type}
                                index={index}
                                handleDelete={handleDelete}
                                handle_add_user_to_sandbox={
                                  handle_add_user_to_sandbox
                                }
                                user={true}
                                title="User"
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>

                {isUserNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50, { label: "All", value: -1 }]}
            component="div"
            count={users ? count : 0}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>

            <Divider className="my-2" />
            <img
              src={s3baseUrl + imagePath}
              alt="images"
              style={{ width: "100%" }}
            />
          </Box>
        </Modal>
      </Container>
    </Page>
  );
}
