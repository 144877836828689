import React from "react";
import { useState } from "react";
import { useSnackbar } from "notistack";
import { Iconify, Page, Scrollbar } from "src/components";
import {
  Card,
  Stack, 
  Button,
  Container,
  TableContainer,
  CircularProgress,
} from "@mui/material";
import { useEffect } from "react";
import EditDashboardImage from "./components/EditDashboardImage";
import AddDashboardImage from "./components/AddDashboardImage";
import CustomConfirmation from "src/components/CustomConfirmation";
import { getDashboardImageAndCategory } from "src/DAL/dashboardImage";
import { s3baseUrl } from "src/config/config";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};

const DashboardSettings = () => {
  const { enqueueSnackbar } = useSnackbar();
  const [dashboadImagesAndCategories, setDashboadImagesAndCategories] =
    useState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleEdit = () => {
    setOpenEditDrawer(true);
  };
  const handleAdd = () => {
    setOpenAddDrawer(true);
  };

  const functionGetDashboardImage = async () => {
    const result = await getDashboardImageAndCategory();
    if (result.code == 200) {
      setDashboadImagesAndCategories(result.setting);
      console.log(result, "sadaskdjaksjdh");
    } else {
      console.log(`Error: ${result.message}`);
    }
  };

  useEffect(() => {
    functionGetDashboardImage();
  }, []);

  return (
    <Page title="Dashboard Setting" style={{ margin: "20px 0" }}>
      {/* <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop> */}
      <>
        {dashboadImagesAndCategories && (
          <EditDashboardImage
            dashboadImagesAndCategories={dashboadImagesAndCategories}
            setDashboadImagesAndCategories={setDashboadImagesAndCategories}
            openEditDrawer={openEditDrawer}
            setOpenEditDrawer={setOpenEditDrawer}
            functionGetDashboardImage={functionGetDashboardImage}
          />
        )}
        {dashboadImagesAndCategories && <AddDashboardImage
          // showAddedDataWithoutApi={showAddedDataWithoutApi}
          openAddDrawer={openAddDrawer}
          setOpenAddDrawer={setOpenAddDrawer}
          functionGetDashboardImage={functionGetDashboardImage}
          dashboadImagesAndCategories={dashboadImagesAndCategories}
        />}
      </>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
      // handleAgree={handleDelete}
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            Dashboard Settings
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <div className="p-4">
                <h5>Dashboard Setting</h5>
              </div>
              {dashboadImagesAndCategories && <div>
                <img
                  src={s3baseUrl + dashboadImagesAndCategories?.images.large}
                  alt="dashboard-image"
                  width="100%"
                  height="250px"
                  style={{ objectFit: "cover", objectPosition: "center" }}
                />
              </div>}
              <div className="px-3 mt-3">
                <h6>Selected Category</h6>
                <p>Add your favorite categories and see them instantly on your mobile app for a personalized experience.</p>
              </div>
              <div className="p-3">
                <ul className="category-list list-unstyled">
                  {dashboadImagesAndCategories &&
                    dashboadImagesAndCategories.categories.map((val, index) => {
                      return <li>{val.name}</li>;
                    })}
                </ul>
              </div>
              <div className="d-flex justify-content-center mb-3">
                <Button
                  variant="contained"
                  startIcon={<Iconify icon="eva:plus-fill" />}
                  onClick={() => {
                    handleEdit();
                  }}
                >
                  Edit
                </Button>
              </div>
            </TableContainer>
          </Scrollbar>
        </Card>
      </Container>
    </Page>
  );
};

export default DashboardSettings;
