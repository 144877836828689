import {useEffect, useState} from 'react';
import { Button, Dialog, DialogActions, DialogTitle } from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";

function CustomConfirmation({ open,setOpen, handleAgree, title, message }) {
  const [loading, setLoading] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);

  const handleCloseDialog = () => {
    setLoading(false);
    setOpen(false);
  };

  useEffect(()=> {
   if (open){
    setLoading(true);
   }
   else{
    setLoading(false);
    setIsLoadingBtn(false);
   }
  },[open])
     
  return (
    <>
      <Dialog open={open} onClose={handleCloseDialog}>
        <DialogTitle>
        {/* {title ? title  : `Are you sure you want to delete `} */}
        {message ? message : `Are you sure you want to delete this ${title}`}
        </DialogTitle>
        <DialogActions>
          <Button onClick={handleCloseDialog}>Cancel</Button>
          <LoadingButton
            // loading={isLoadingBtn}
            disabled={isLoadingBtn}
            variant="text"
            onClick={() => {
              setIsLoadingBtn(true);
              handleAgree();
            }}
          >
            {isLoadingBtn ? "Loading..." : "Yes, Continue"}
          </LoadingButton>
        </DialogActions>
      </Dialog>
    </>
  );
}

export default CustomConfirmation;
