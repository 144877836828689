export const showAddedDataWithoutApi = (newData, previousData, setData) => {
  console.log(previousData,"acaslkcjaskch")
    setData([newData, ...previousData]);
  };

export const showEditedDataWithoutApi = (index, newData, previousData, setData) => {
    let x = previousData.filter((row,i) => i!==index);
    setData([newData, ...x]);
  }

export const hideDeletedDataWithoutApi = (index, previousData, setData) => {
    let x = previousData.filter((row,i) => i!==index);
    setData([...x])
}