import React, { useState, useEffect } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import MoneyOffIcon from "@mui/icons-material/MoneyOff";
import { supportedLanguages } from "src/constants";

import {
  uploadImage,
  uploadAudio,
  upload_image_api,
} from "src/DAL/uploadFiles";
import { useSnackbar } from "notistack";
import DatePickerField from "src/components/Datepicker";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Alert,
  AlertTitle,
  ToggleButton,
  ToggleButtonGroup,
  Switch,
  Box,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { useAppContext } from "src/hooks";
import moment from "moment";
import {
  addStory,
  editStory,
  storyDetail,
  videoUploadApi,
} from "src/DAL/story";
import { VideoCameraBack } from "@mui/icons-material";
import { s3baseUrl } from "src/config/config";
import { CircularLoader } from "src/components";

const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      width: 200,
      minHeight: "50px",
      maxHeight: "500px",
      overflowY: "auto",
    },
  },
};

function EditStoryDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  categories,
  showAddedDataWithoutApi,
  tracks,
  setTracks,
  count,
  setCount,
  funcTracks,
  storyId,
}) {
  const { checked } = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [author, setAuthor] = useState("");
  const [selectedImage, setSelectedImage] = useState(null);

  const [storyImage, setStoryImage] = useState(null);
  const [selectedAudio, setSelectedAudio] = useState(null);
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState(null);
  const [isPaid, setIsPaid] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState("");
  // const [isPublished, setIsPublished] = useState(false);

  const [notificationDescription, setNotificationDescription] = useState("");

  const [notificationDate, setNotificationDate] = useState(
    new Date(moment().add(5, "minutes"))
  );
  const [urlLink, setUrlLink] = useState("");

  const [segment, setSegment] = useState("day");
  const [mediaType, setMediaType] = useState("image");
  const [videoLink, setVideoLink] = useState("");
  const [selectedVideo, setSelectedVideo] = useState(null);
  const [description, setDescription] = useState("");
  const [videourl, setVideoUrl] = useState("");
  const [videothumbnail, setVideothumbnail] = useState("");
  const [selectedLanguages, setSelectedLanguages] = useState([]);
  const [selectedUrl, setSelectedUrl] = useState("");

  const [status, setStatus] = useState("");
  const [storycatogeries, setStorycatogeries] = useState([]);
  const [selectedThumbnail, setSelectedThumbnail] = useState(null);
  const [thumbnailPreview, setThumbnailPreview] = useState(null);
  const [storyImagePreview, setStoryImagePreview] = useState(null);
  const [switchNotifications, setSwitchNotifications] = useState(false);
  const [storytype, setStorytype] = useState("");
  const [story_type, setStory_type] = useState("");
  const [oldstoryimage, setOldStoryimage] = useState("");
  const [thumbnai, setThumbnai] = useState("");
  const [sandboxUser, setSandBoxuser] = useState(false);
  const [videoUrllink, setVideoUrllink] = useState("");
  const [videoOption, setVideoOption] = useState("custom");

  const [thumbnailUrlFromDetailApi, setThumbnailUrlFromDetailApi] =
    useState(null);
  const [imageUrlFromDetailApi, setImageUrlFromDetailApi] = useState(null);
  const [videoUrlFromDetailApi, setVideoUrlFromDetailApi] = useState(null);
  const [isPublished, setIsPublished] = useState(false);

  useEffect(() => {
    if (openAddDrawer) {
      (async () => {
        try {
          const res = await storyDetail(storyId);
          const story = res.story;
          if (res.code == 200) {
            setName(story.title);
            setDescription(story.description);
            if (res.story.languages?.length > 0) {
              setSelectedLanguages(res.story.languages);
            }
            if (story.video_url) {
              setUrlLink(story.video_url);
            } else {
              setUrlLink("");
            }

            setSelectedCategories(
              story.story_categories.map((item) => item._id)
            );
            setStatus(story.status);
            setMediaType(
              story.story_type === "CUSTOM_VIDEO"
                ? "video"
                : story.story_type === "VIDEO_URL"
                  ? "video"
                  : "image"
            );

            setVideoOption(
              story.story_type === "CUSTOM_VIDEO"
                ? "custom"
                : story.story_type === "VIDEO_URL"
                  ? "url"
                  : ""
            );
            setNotificationTitle(res.story.notification_title);
            setNotificationDescription(res.story.notification_description);
            setIsPublished(res.story.is_published);

            // setStoryImage(story.image);
            // setOldStoryimage(story.image);
            // setSwitchNotifications(story.notification_status);
            setStorytype("CUSTOM_VIDEO");
            // setThumbnai(story.video_thumbnail);
            setSandBoxuser(story.is_sandbox);

            // Set preview images
            if (story.story_type == "IMAGE") {
              if (story.image) {
                setStoryImagePreview(s3baseUrl + story.image);
                setImageUrlFromDetailApi(story.image);
              }
            } else {
              if (story.video_thumbnail) {
                setThumbnailPreview(s3baseUrl + story.video_thumbnail);
                setThumbnailUrlFromDetailApi(story.video_thumbnail);
              }
              if (story.video_url) {
                setVideoUrl(story.video_url);
                setVideoUrlFromDetailApi(story.video_url);
              } else {
                setVideoUrlFromDetailApi(null);
              }
            }
          }
        } catch (error) {
          console.log(error);
          enqueueSnackbar("Failed to load story details", { variant: "error" });
        }
      })();
    }
  }, [openAddDrawer]);

  const handleMediaTypeChange = (event, newMediaType) => {
    if (newMediaType !== null) {
      setMediaType(newMediaType);
    }
  };
  console.log("media type ", mediaType);

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("selectdCatogeries", selectedCategories);

    if (selectedCategories.length > 0) {
      setLoading(true);
      console.log("selectdCatogeries", selectedCategories);
      let newCat,
        newSelectedCategories = [];
      selectedCategories.map((category) => {
        newCat = category;
        newSelectedCategories = [...newSelectedCategories, newCat];
      });

      const formDataImage = new FormData();
      const formDataThumbnail = new FormData();
      const formDataVideo = new FormData();
      const formDataStoryImage = new FormData();

      formDataImage.append("image", selectedImage);
      if (mediaType === "video") {
        formDataThumbnail.append("image", selectedThumbnail);
        formDataVideo.append("video", selectedVideo);
      } else if (mediaType === "image") {
        formDataStoryImage.append("image", storyImage);
      }

      try {
        let responseThumbnail = "";
        let responseVideo = "";

        if (mediaType === "video") {
          console.log(videoOption, "videoOption video thmburl");

          if (videoOption == "custom") {
            if (selectedThumbnail && selectedVideo) {
              responseThumbnail = await upload_image_api(formDataThumbnail);
              if (responseThumbnail.code == 200) {
                responseVideo = await videoUploadApi(formDataVideo);
                if (responseVideo.code == 200) {
                  let story = {
                    title: name,
                    status: status,
                    story_type: "CUSTOM_VIDEO",
                    description: description,
                    video_url: responseVideo?.file?.path,
                    video_thumbnail: responseThumbnail?.path,
                    languages: selectedLanguages,
                    notification_title: notificationTitle,
                    notification_description: notificationDescription,

                    story_categories: newSelectedCategories,
                    // is_published: isPublished,

                    // is_sandbox: true,
                    // notification_status: false,
                  };
                  const res = await editStory(storyId, story);
                  if (res.code == 200) {
                    funcTracks();
                    setCount(count + 1);
                    handleClose();
                    enqueueSnackbar(res.message, { variant: "success" });
                  } else {
                    enqueueSnackbar(res.message, { variant: "error" });
                  }
                }
              }
            } else if (selectedThumbnail) {
              responseThumbnail = await upload_image_api(formDataThumbnail);
              console.log(formDataThumbnail, "formDataThumbnail");

              if (responseThumbnail.code == 200) {
                let story = {
                  title: name,
                  status: status,
                  story_type: "CUSTOM_VIDEO",
                  description: description,
                  video_url: videoUrlFromDetailApi,
                  video_thumbnail: responseThumbnail?.path ?? "",
                  story_categories: newSelectedCategories,
                  // is_sandbox: true,
                  // notification_status: false,
                  languages: selectedLanguages,
                  notification_title: notificationTitle,
                  notification_description: notificationDescription,
                  // is_published: isPublished,
                };
                const res = await editStory(storyId, story);
                if (res.code == 200) {
                  funcTracks();
                  setCount(count + 1);
                  handleClose();
                  enqueueSnackbar(res.message, { variant: "success" });
                } else {
                  enqueueSnackbar(res.message, { variant: "error" });
                }
              }
            } else if (selectedVideo) {
              responseVideo = await videoUploadApi(formDataVideo);
              if (responseVideo.code == 200) {
                let story = {
                  title: name,
                  status: status,
                  story_type: "CUSTOM_VIDEO",
                  description: description,
                  video_url: responseVideo?.file?.path,
                  video_thumbnail: thumbnailUrlFromDetailApi ?? "",
                  story_categories: newSelectedCategories,
                  // is_sandbox: true,
                  // notification_status: false,
                  languages: selectedLanguages,
                  notification_title: notificationTitle,
                  notification_description: notificationDescription,
                  // is_published: isPublished,
                };
                const res = await editStory(storyId, story);
                if (res.code == 200) {
                  funcTracks();
                  setCount(count + 1);
                  handleClose();
                  enqueueSnackbar(res.message, { variant: "success" });
                } else {
                  enqueueSnackbar(res.message, { variant: "error" });
                }
              }
            } else {
              let story = {
                title: name,
                status: status,
                story_type: "CUSTOM_VIDEO",
                description: description,
                video_url: videoUrlFromDetailApi,
                video_thumbnail: thumbnailUrlFromDetailApi ?? "",
                story_categories: newSelectedCategories,
                // is_sandbox: true,
                // notification_status: false,
                languages: selectedLanguages,
                notification_title: notificationTitle,
                notification_description: notificationDescription,
                // is_published: isPublished,
              };
              const res = await editStory(storyId, story);
              if (res.code == 200) {
                funcTracks();
                setCount(count + 1);
                handleClose();
                enqueueSnackbar(res.message, { variant: "success" });
              } else {
                enqueueSnackbar(res.message, { variant: "error" });
              }
            }
          } else {
            let _videothumbnailUrl;

            if (selectedThumbnail) {
              responseThumbnail = await upload_image_api(formDataThumbnail);
              if (responseThumbnail.code == 200) {
                _videothumbnailUrl = responseThumbnail?.path;
              }
            } else {
              _videothumbnailUrl = thumbnailUrlFromDetailApi;
            }
            console.log(_videothumbnailUrl, "video thmburl");
            console.log(
              videoUrlFromDetailApi,
              "videoUrlFromDetailApi video thmburl"
            );

            let story = {
              title: name,
              status: status,
              story_type: "VIDEO_URL",
              description: description,
              video_url:
                videoOption === "url" ? urlLink : videoUrlFromDetailApi,
              video_thumbnail: _videothumbnailUrl ?? "",
              story_categories: newSelectedCategories,
              // is_sandbox: true,
              // notification_status: false,
              languages: selectedLanguages,
              notification_title: notificationTitle,
              notification_description: notificationDescription,
              // is_published: isPublished,
            };
            const res = await editStory(storyId, story);
            if (res.code == 200) {
              funcTracks();
              setCount(count + 1);
              handleClose();
              enqueueSnackbar(res.message, { variant: "success" });
            } else {
              enqueueSnackbar(res.message, { variant: "error" });
            }
          }
        } else if (mediaType === "image") {
          let responseStoryImage = {};
          if (storyImage) {
            responseStoryImage = await upload_image_api(formDataStoryImage);
            if (responseStoryImage.code === 200) {
              let story = {
                title: name,
                status: status,
                story_type: "IMAGE",
                image: responseStoryImage?.path ?? storyImage ?? "",
                description: description,
                story_categories: newSelectedCategories,
                // is_sandbox: true,
                // notification_status: false,
                languages: selectedLanguages,
                notification_title: notificationTitle,
                notification_description: notificationDescription,
                // is_published: isPublished,
              };
              const res = await editStory(storyId, story);
              console.log(res, "edit api result ........");
              if (res.code === 200) {
                funcTracks();
                setCount(count + 1);
                handleClose();
                enqueueSnackbar(res.message, { variant: "success" });
              } else {
                enqueueSnackbar(res.message, { variant: "error" });
              }
            } else {
              enqueueSnackbar("Please Upload Image.", { variant: "error" });
            }
          } else {
            let story = {
              title: name,
              status: status,
              story_type: "IMAGE",
              image: imageUrlFromDetailApi ?? "",
              description: description,
              story_categories: newSelectedCategories,
              // is_sandbox: true,
              // notification_status: false,
              languages: selectedLanguages,
              notification_title: notificationTitle,
              notification_description: notificationDescription,
              // is_published: isPublished,
            };
            const res = await editStory(storyId, story);
            console.log(res, "edit api result ........");
            if (res.code === 200) {
              funcTracks();
              setCount(count + 1);
              handleClose();
              enqueueSnackbar(res.message, { variant: "success" });
            } else {
              enqueueSnackbar(res.message, { variant: "error" });
            }
          }
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Could not Upload File(s), Please try again.", {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      enqueueSnackbar("Please select at least one category.", {
        variant: "error",
      });
    }
  };
  const handleVideoOptionChange = (event) => {
    setVideoOption(event.target.value);
    // console.log(videoOption, " videoOption");

    //   if(videoOption == "custom"){
    //       setMediaType('video')
    //   }else{
    //       setMediaType('video_url')
    //   }
    //   console.log(mediaType, ' mediatype')
  };

  const handleClose = () => {
    console.log("handle close called");
    setName("");
    setAuthor("");
    setSelectedImage(null);
    setImagePreview(null);
    setSelectedAudio(null);
    setStoryImage(null);
    setStoryImagePreview(null);
    setSelectedCategories([]);
    setLoading(false);
    setSwitchNotifications(false);
    setDescription("");
    setVideothumbnail("");
    setVideoUrl("");
    setVideoLink("");
    setSelectedVideo(null);
    setMediaType("image");
    setStorytype("");
    setSelectedThumbnail(null);
    setThumbnailPreview(null);
    setStatus("");
    setSelectedLanguages([]);
    setSelectedUrl("");
    setUrlLink("");
    setThumbnailUrlFromDetailApi(null);
    setVideoUrlFromDetailApi(null);
    setImageUrlFromDetailApi(null);
    setOpenAddDrawer(false);
  };

  useEffect(() => {
    if (openAddDrawer) {
      setNotificationDate(new Date(moment().add(5, "minutes")));
    }
  }, [openAddDrawer]);

  useEffect(() => {
    if (selectedImage) {
      setImagePreview(URL.createObjectURL(selectedImage));
    }
  }, [selectedImage]);

  useEffect(() => {
    console.log(selectedThumbnail, "selectedThumbnail Preview");
    if (selectedThumbnail) {
      setThumbnailPreview(URL.createObjectURL(selectedThumbnail));
    } else {
      setThumbnailPreview(null);
    }
  }, [selectedThumbnail]);

  useEffect(() => {
    if (storyImage) {
      setStoryImagePreview(URL.createObjectURL(storyImage));
    } else {
      setStoryImagePreview(null);
    }
  }, [storyImage]);

  const handleImageChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedImage(file);
      e.target.parentElement.querySelector("span").innerText = file.name;
    }
  };

  const handleThumbnailChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      setSelectedThumbnail(file); // Set selected thumbnail
      setThumbnailPreview(URL.createObjectURL(file)); // Set thumbnail preview
    }
    // setSelectedThumbnail(file);
  };

  const handleVideoChange = (e) => {
    const file = e.target.files[0];

    if (file) {
      setSelectedVideo(file);
    }
    // handleVideoChange;
  };

  return (
    <Drawer
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
      PaperProps={{
        sx: { width: "28%" },
      }}
    >
      <Container>
        <Typography variant="h4" sx={{ mt: 5, mb: 5 }}>
          Edit Story
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to{" "}
              <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>28 Notifications will
              be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        <Alert severity="info" color="primary" sx={{ mb: 3 }}>
          <small>Default Language is English</small>
        </Alert>

        <form onSubmit={handleSubmit}>
          <TextField
            fullWidth
            label="Title"
            value={name}
            onChange={(e) => setName(e.target.value)}
            sx={{ mb: 2 }}
          />
          <TextField
            fullWidth
            label="Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            sx={{ mb: 2 }}
            multiline
            rows={4}
          />

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Categories</InputLabel>
            <Select
              required
              label="Categories"
              multiple
              value={selectedCategories}
              onChange={(e) => setSelectedCategories(e.target.value)}
              MenuProps={MenuProps}
            >
              {categories &&
                categories.map((category) => {
                  return (
                    <MenuItem key={category._id} value={category._id}>
                      {category.title}
                    </MenuItem>
                  );
                })}
              {!categories && "No category Found"}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel>Languages</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              label="Languages"
              multiple
              id="demo-simple-select"
              value={selectedLanguages}
              onChange={(e) => setSelectedLanguages(e.target.value)}

              //MenuProps={MenuProps}
            >
              {supportedLanguages?.map((name) => {
                return (
                  <MenuItem className="text-capitalize" value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setNotificationTitle(e.target.value)}
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              value={notificationTitle}
              disabled={Boolean(isPublished)}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setNotificationDescription(e.target.value)}
              id="outlined-multiline-static"
              label="Notification Description"
              multiline
              rows={4}
              defaultValue=""
              value={notificationDescription}
              disabled={Boolean(isPublished)}
            />
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <ToggleButtonGroup
              value={mediaType}
              exclusive
              onChange={handleMediaTypeChange}
              aria-label="media type"
            >
              <ToggleButton
                value="image"
                aria-label="image"
                style={{
                  backgroundColor: mediaType === "image" ? "#87c326" : "",
                  color: mediaType === "image" ? "white" : "#87c326",
                  borderRadius: "5px",
                  width: "200px",
                  height: "35px",
                  boxShadow: mediaType === "image" ? "0 4px 8px gray" : "none",
                }}
              >
                Image
              </ToggleButton>
              <ToggleButton
                value="video"
                aria-label="video"
                style={{
                  backgroundColor: mediaType === "video" ? "#87c326" : "",
                  color: mediaType === "video" ? "white" : "#87c326",
                  borderRadius: "5px",
                  width: "200px",
                  height: "35px",
                  boxShadow: mediaType === "video" ? "0 4px 8px gray" : "none",
                }}
              >
                Video
              </ToggleButton>
            </ToggleButtonGroup>
          </FormControl>

          {mediaType === "image" && (
            <Grid container spacing={2}>
              <Grid item xs={storyImage ? 8 : 12}>
                <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                  <Button variant="contained" component="label">
                    {!storyImage && (
                      <>
                        Select Image <PhotoCamera sx={{ ml: 1 }} />
                      </>
                    )}
                    {storyImage && (
                      <>
                        <PhotoCamera sx={{ mr: 1 }} /> {storyImage.name}
                      </>
                    )}
                    <input
                      hidden
                      name="image"
                      accept="image/jpeg, image/png, image/jpg, image/webp, image/HEIC"
                      type="file"
                      onChange={(e) => {
                        setStoryImage(e.target.files[0]);
                        setStoryImagePreview(
                          URL.createObjectURL(e.target.files[0])
                        );
                      }}
                    />
                  </Button>
                </FormControl>
                <small>
                  Recommended Image size for stories (1080 x 1920px) file type
                  (jpg,png)
                </small>
              </Grid>
              {storyImagePreview && (
                <Grid item xs={4} sx={{ marginTop: "60px" }}>
                  <img
                    src={storyImagePreview}
                    alt="image"
                    width="100"
                    height="auto"
                  />
                </Grid>
              )}
            </Grid>
          )}
          {mediaType === "video" && (
            <>
              <FormControl fullWidth sx={{ mb: 0 }}>
                <InputLabel id="demo-simple-select-label">
                  Video Type
                </InputLabel>
                <Select
                  required
                  label="Video Type"
                  value={videoOption}
                  onChange={handleVideoOptionChange}
                >
                  <MenuItem value="custom">Custom Video</MenuItem>
                  <MenuItem value="url">URL Link</MenuItem>
                </Select>
              </FormControl>

              {videoOption === "custom" && (
                <>
                  <FormControl fullWidth sx={{ mb: 2 }}>
                    <Button
                      sx={{ marginTop: "20px" }}
                      variant="contained"
                      component="label"
                    >
                      {!selectedVideo && !videoUrlFromDetailApi && (
                        <>
                          Select Video <PhotoCamera sx={{ ml: 1 }} />
                        </>
                      )}
                      {selectedVideo && (
                        <>
                          <PhotoCamera sx={{ mr: 1 }} /> {selectedVideo.name}
                        </>
                      )}
                      {!selectedVideo && videoUrlFromDetailApi && (
                        <>
                          <PhotoCamera sx={{ mr: 1 }} />
                          Select Video
                        </>
                      )}
                      <input
                        hidden
                        name="video"
                        accept="video/mp4, video/mkv, video/webm"
                        type="file"
                        onChange={handleVideoChange}
                      />
                    </Button>
                    <small style={{ marginTop: "15px" }}>
                      Recommended Video size for stories 1080 x 1920 px file
                      type (mp4)
                    </small>
                  </FormControl>
                </>
              )}

              {videoOption === "url" && (
                <FormControl fullWidth sx={{ mb: 2 }}>
                  <TextField
                    label="Video URL"
                    variant="outlined"
                    value={urlLink}
                    onChange={(e) => setUrlLink(e.target.value)}
                    fullWidth
                    sx={{ marginTop: "20px" }}
                  />
                </FormControl>
              )}

              <Grid container spacing={2}>
                <Grid item xs={thumbnailPreview ? 8 : 12}>
                  <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                    <Button
                      sx={{ marginTop: "10px" }}
                      variant="contained"
                      component="label"
                    >
                      {!selectedThumbnail && (
                        <>
                          Video Thumbnail
                          <PhotoCamera sx={{ ml: 1, marginTop: "" }} />
                        </>
                      )}
                      {selectedThumbnail && (
                        <>
                          <PhotoCamera sx={{ mr: 1 }} />{" "}
                          {selectedThumbnail.name}
                        </>
                      )}
                      <input
                        hidden
                        name="image"
                        accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                        type="file"
                        onChange={handleThumbnailChange}
                      />
                    </Button>
                  </FormControl>
                  <small>
                    Recommended Video Thumbnail size (1080x1920) and file type
                    (jpg,png)
                  </small>
                </Grid>

                {thumbnailPreview && (
                  <Grid item xs={4} sx={{ marginTop: "70px" }}>
                    <img
                      src={thumbnailPreview}
                      alt="image"
                      width="100"
                      height="auto"
                    />
                  </Grid>
                )}
              </Grid>
            </>
          )}

          <LoadingButton
            sx={{ marginTop: "30px", marginBottom: "20px" }}
            loading={loading}
            type="submit"
            variant="contained"
          >
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default EditStoryDrawer;
