import React, { useEffect, useState } from "react";
import { s3baseUrl } from "src/config/config";
import CustomConfirmation from "src/components/CustomConfirmation";
import { filter } from "lodash";
import { useNavigate } from "react-router-dom";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop, 
  CircularProgress,
  Badge,
  Tooltip,
} from "@mui/material";
// import Badge from '@mui/material-next/Badge';
import {
  Page,
  Scrollbar,
  Iconify,
  SearchNotFound,
  Label,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import CustomLabel from "src/components/loaders/CustomLabel";
import MoreMenu from "src/components/MoreMenu";
import {
  closeSupportTicket,
  deleteSupportTicket,
  getReports,
  update_reportAction,
} from "src/DAL/supportTicket";
import moment from "moment";
import { hideDeletedDataWithoutApi } from "src/DAL/updateWithoutApi";
import { useSnackbar } from "notistack";
import IconButton from "src/theme/overrides/IconButton";
import { Link } from "react-router-dom";
import SupportTicketDetail from "./SupportTicketDetail";
import CustomPopover from "src/components/loaders/CustomPopover";
import { set } from "lodash";
import { useAppContext } from "src/hooks";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};

const tabs = {
  all: "all",
  open: "open",
  close: "closed",
};

const TABLE_HEAD = [
  // { id: "count", label: "#", alignRight: false },
  { id: "subject", label: "Title", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "topic", label: "Type", alignRight: false },
  { id: "title", label: "Name", alignRight: false },
  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Date", alignRight: false },
  { id: "time", label: "Time", alignRight: false },
  { id: "", label: "Action", alignRight: false },
];

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => -descendingComparator(a, b, orderBy);
}

const SupportTicket = () => {

  const {setAction} = useAppContext();
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();

  const [openBackdrop, setOpenBackdrop] = useState(false);
  const [status, setStatus] = useState(true);
  const [filterName, setFilterName] = useState("");
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [totalCount, setTotalCount] = useState(0);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [supportTicketList, setSupportTicketList] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [openComplete, setOpenComplete] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [open_ticket_count, setOpen_ticket_count] = useState(0);
  const [close_ticket_count, setClose_ticket_count] = useState(0);
  const [count, setCount] = useState(0);
  const [selectedRow, setSelectedRow] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [allCount, setAllCount] = useState(0);
  const [openCount, setOpenCount] = useState(0);
  const [closeCount, setCloseCount] = useState(0);

  const [selectedTab, setSelectedTab] = useState(tabs.open);

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_track) =>
          _track?.name?.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis.map((el) => el[0]);
  }

const getReportListing= async ()=>{
try{
  const result = await getReports(page, rowsPerPage, selectedTab, filterName);
  if (result.code === 200) {
    console.log(result, "resultreport");
    setTotalCount(result.count ? result.count : 0);
    // setOpenBackdrop(false);
    // setIsLoading(false);
    setSupportTicketList(result?.report_us);
    setAllCount(result.all_count);
    setOpenCount(result.open_count);
    setCloseCount(result.closed_count);
    setCount(result?.count);
  } else {
    console.log(`Error: ${result.code}`);
    // setOpenBackdrop(false);
  }
}
catch (error) {
  console.log(error);
} finally {
  setOpenBackdrop(false);
}
}

const updateReport_action = async () => {
  const updateAction_resp = await update_reportAction();
  console.log("resp_update_action_detail", updateAction_resp);
  if (updateAction_resp.code == 200) {
     setAction(false);
  } else {
    enqueueSnackbar(updateAction_resp.message, { variant: "error" });
  }
};

useEffect(()=>{
  updateReport_action();
}, [])

useEffect(() => {
  setSupportTicketList();
  setOpenBackdrop(true);
}, [page, rowsPerPage]);

useEffect(() => {
  setPage(0);
  getReportListing();
}, [filterName]);

useEffect(() => {
  setOpenBackdrop(true);
  getReportListing();
}, [page, rowsPerPage, selectedTab]);

  // const getReportListing = async () => {
  //  // setIsLoading(true);
  //   // setOpenBackdrop(true);
  //   const result = await getReports(page, rowsPerPage, selectedTab, filterName);
  //   if (result.code === 200) {
  //     console.log(result, "resultreport");
  //     setTotalCount(result.count ? result.count : 0);
  //     // setOpenBackdrop(false);
  //     // setIsLoading(false);
  //     setSupportTicketList(result?.report_us);
  //     setAllCount(result.all_count);
  //     setOpenCount(result.open_count);
  //     setCloseCount(result.closed_count);
  //     setCount(result?.count);
  //   } else {
  //     console.log(`Error: ${result.code}`);
  //     setOpenBackdrop(false);
  //   }
  // };

  console.log(supportTicketList, "supportTicketList");
  console.log(selectedTab, "selectedTab");

  // useEffect(() => {
  //   setSupportTicketList();
  //   // setOpenBackdrop(true);
  // }, [page, rowsPerPage, selectedTab]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };

  const handleDeleteSelected = async (row) => {
    setOpenDelete(true);
    setSelectedRow(row);
  };

  const handleCompleteSelected = async (row) => {
    console.log(row, "row");
    setOpenComplete(true);
    setSelectedRow(row);
  };

  const handleDelete = async () => {
    console.log(selectedRow, "id");
    // setIsLoadingBtn(true);
    try {
      const response = await deleteSupportTicket(selectedRow._id);
      if (response.code === 200) {
        // setCount(count - 1);
        // hideDeletedDataWithoutApi(index, supportTicketList, setSupportTicketList);
        // setCount(count - 1);
        // getReportListing();
        enqueueSnackbar(response.message, { variant: "success" });
        setIsLoadingBtn(false);
        getReportListing();
        setIsLoadingBtn(false);
        setOpenDelete(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
        setIsLoadingBtn(false);
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleCompleteClick = async () => {
    console.log(selectedRow, "id");
    setIsLoadingBtn(true);
    try {
      const response = await closeSupportTicket(selectedRow._id);
      console.log(response, "response");
      if (response.code == 200) {
        enqueueSnackbar(response.message, { variant: "success" });
        getReportListing();
        setIsLoadingBtn(false);
        setOpenComplete(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/support_ticket/support_ticket_detail/${row._id}`);
  };

  const handleChangeTab = (tab) => {
    setSelectedTab(tab);
    setPage(0);
    setRowsPerPage(50);
    setFilterName("");
  };

  const filteredList = supportTicketList
  ? applySortFilter(supportTicketList, getComparator(order, orderBy), filterName)
  : [];

  const isListNotFound = filteredList.length === 0;

  const MENU_OPTIONS = (row) => {
    if (row.status === "open") {
      return [
        {
          label: "View Details",
          icon: "carbon:view",
          handleClick: handleRowClick,
        },
        {
          label: "Close Feedback",
          icon: "carbon:task-complete",
          handleClick: handleCompleteSelected,
        },
        {
          label: "Delete",
          icon: "ant-design:delete-twotone",
          handleClick: handleDeleteSelected,
        },
      ];
    }

    return [
      {
        label: "View Details",
        icon: "carbon:view",
        handleClick: handleRowClick,
      },
      {
        label: "Delete",
        icon: "ant-design:delete-twotone",
        handleClick: handleDeleteSelected,
      },
    ];
  };

  useEffect(() => {
    setPage(0);
  }, [filterName]);

  useEffect(() => {
    // setOpenBackdrop(true);
    getReportListing();
  }, [
    status,
    page,
    rowsPerPage,
    order,
    orderBy,
    filterName,
    selectedTab,
    count,
  ]);

  return (
    <>
      <Page title="Feedback">
        <Backdrop
          sx={{
            color: "#fff",
            zIndex: (theme) => theme.zIndex.drawer + 1,
          }}
          open={openBackdrop}
        >
          <CircularProgress color="inherit" />
        </Backdrop>

        <CustomConfirmation
          open={openDelete}
          setOpen={setOpenDelete}
          handleAgree={handleDelete}
          title="Feedback"   
        />

          <CustomConfirmation
            open={openComplete}
            setOpen={setOpenComplete}
            handleAgree={handleCompleteClick}
            message="Are you sure you want to close this Feedback?"
          />

          <Card
            sx={{
              boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px",
              mb: 1,
              margin: "20px",
            }}
          >

          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Feedback"
            placeholder="Search Feedback by title or desc..."
          />

          <Stack
            direction="row"
            alignItems="center"
            justifyContent="space-between"
            mb={3}
            sx={{
              padding: "0px 20px",
            }}
          >
            <div className="d-flex">
              <div className="me-2 pointer">
                <CustomLabel
                  onClick={() => {
                    handleChangeTab(tabs.open);
                  }}
                  variant={selectedTab == tabs.open ? "ghost" : ""}
                  style={{ width: 100, cursor: "pointer" }}
                  color="success"
                >
                  OPEN ({openCount})
                </CustomLabel>
              </div>
              <div className="me-2 pointer">
                <CustomLabel
                  onClick={() => {
                    handleChangeTab(tabs.close);
                  }}
                  variant={selectedTab == tabs.close ? "ghost" : ""}
                  style={{ width: 100, cursor: "pointer" }}
                  color="error"
                >
                  CLOSED ({closeCount})
                </CustomLabel>
              </div>
              <div className="me-2 pointer">
                <CustomLabel
                  onClick={() => {
                    handleChangeTab(tabs.all);
                  }}
                  variant={selectedTab === tabs.all ? "filled" : ""}
                  style={{ width: 100, cursor: "pointer", backgroundColor:selectedTab === tabs.all ? '#deedc5' : " ", color:selectedTab === tabs.all ? "#87c326" : " "}}
                  color="primary"
                >
                  ALL ({allCount})
                </CustomLabel>
              </div>
            </div>
          </Stack>

          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {supportTicketList &&
                    supportTicketList.map((row, index) => {
                      const {
                        _id,
                        subject,
                        unread_count,
                        description,
                        topic,
                        createdAt,
                        status,
                        action_object,
                        is_read,
                      } = row;
                      return ( 
                        <TableRow
                          hover
                          key={index}
                          tabIndex={-1}
                          role="checkbox"
                          sx={{ backgroundColor: unread_count > 0 || !is_read  ? "#e7f7cd" : ''}}
                        >
                          {/* <TableCell>
                             <Typography variant="subtitle2" noWrap>
                              {rowsPerPage * page + (index + 1)}
                            </Typography>
                          </TableCell> */}

                          <TableCell sx={{ maxWidth: 200 }}>
                            <Typography
                              variant="subtitle2"
                              style={{ cursor: "pointer", 
                              // backgroundColor: unread_count > 0 ? "#d5cef2" : '',
                              }}
                              noWrap
                              onClick={() => {navigate(`/support_ticket/support_ticket_detail/${_id}`); }} >
                            {/* <div
                            style={{
                               display:'flex',
                               justifyContent:'center',
                               alignItems:'center',  
                            }}
                            > */}

                             {subject}

                              {/* <p  className="ms-4"
                              style={{padding:'6px', backgroundColor:'#5d48c7', color:'white', borderRadius:'100%', marginLeft:'5px', fontSize:'6px',}}>{unread_count}</p> */} 
                              {/* <p>
                              <Badge
                                badgeContent={unread_count}
                                color="primary"
                                invisible={false}
                                size="large"
                                style={{marginLeft:'35px'}}
                              />
                              </p> */}
                            {/* </div> */}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 200 }}>
                            <Typography variant="subtitle2" noWrap>
                              {description}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 100 }}>
                              <Typography variant="subtitle2" noWrap>
                                {topic}
                              </Typography>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 200 }}>
                            <Typography variant="subtitle2" noWrap>
                              {action_object?.title}
                            </Typography>
                          </TableCell>
                          <TableCell align="left">
                            <Label
                              variant="outlined"
                              color={
                                (status === "open" && "success") || "error"
                              }>
                              {status === "open" ? "Open" : "Close"}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {moment(createdAt).format("DD MMMM YYYY")}
                            </Typography>
                          </TableCell>
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {moment(createdAt).format("hh:mm A")}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <CustomPopover
                              data={row}
                              menu={MENU_OPTIONS(row)}
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                </TableBody>
                {!openBackdrop && !supportTicketList && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>

          <TablePagination
            // rowsPerPageOptions={[5, 10, 25, 50, { label: "All", value: -1 }]}
            rowsPerPageOptions={[5, 10, 25, 50, 100]}
            component="div"
            // count={supportTicketList?.length || 0}
            count={totalCount}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Page>
    </>
  );
};

export default SupportTicket;
