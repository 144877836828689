import { useRef, useState } from "react";
import CustomConfirmation from "./CustomConfirmation";
import {
  Menu,
  MenuItem,
  IconButton,
  ListItemIcon,
  ListItemText,
  Tooltip,
} from "@mui/material";
import Iconify from "./Iconify";

export default function MoreMenu({
  handleDelete,
  handleEdit,
  handle_add_user_to_sandbox,
  id,
  user_type,
  setEditId,
  setEditIndex,
  setLanguage,
  handleLanguage,
  index,
  title,
  isDefault = false,
  set_is_published,
  is_published_status,
  tooltip = "Cannot delete Default Category",
}) {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);
  const [openDelete, setOpenDelete] = useState(false);

  const handleOk = () => {
    handleDelete(id, index);
    setIsOpen(false);
  };

  const handleAddToSandbox = (id) => {
    handle_add_user_to_sandbox(id, user_type);
    setIsOpen(false);
  };

  const handleClickLanguage = (language) => {
    set_is_published(is_published_status);
    handleLanguage(id, language);
    setIsOpen(false);
  };

  return (
    <>
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleOk}
        title={title}
      />
      <IconButton ref={ref} onClick={() => setIsOpen(true)}>
        <Iconify icon="eva:more-vertical-fill" width={20} height={20} />
      </IconButton>

      <Menu
        open={isOpen}
        anchorEl={ref.current}
        onClose={() => setIsOpen(false)}
        PaperProps={{
          sx: { width: 250, maxWidth: "100%" },
        }}
        anchorOrigin={{ vertical: "top", horizontal: "right" }}
        transformOrigin={{ vertical: "top", horizontal: "right" }}
      >
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => {
            setIsOpen(false);
            setEditId(id);
            handleEdit();
            setEditIndex(index);
          }}
        >
          <ListItemIcon>
            <Iconify icon="eva:edit-fill" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Edit"
            primaryTypographyProps={{ variant: "body2" }}
          />
        </MenuItem>
        <MenuItem
          sx={{ color: "text.secondary" }}
          onClick={() => setOpenDelete(true)}
        >
          <ListItemIcon>
            <Iconify icon="eva:trash-2-outline" width={24} height={24} />
          </ListItemIcon>
          <ListItemText
            primary="Delete"
            primaryTypographyProps={{
              variant: "body2",
              sx: { display: "flex", alignItems: "center", height: "100%" },
            }}
          />
        </MenuItem>
        {isDefault && (
          <MenuItem sx={{ color: "lightgray" }} disabled>
            <ListItemIcon>
              <Iconify icon="eva:trash-2-outline" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Delete"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}

        {setLanguage?.map((language, idx) => (
          <MenuItem
            key={idx}
            sx={{ color: "text.secondary", textTransform: "capitalize" }}
            onClick={() => {
              handleClickLanguage(language);
            }}
          >
            <ListItemIcon>
              <Iconify icon="mdi:translate" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary={`Add content in ${language}`}
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        ))}

        {handle_add_user_to_sandbox && (
          <MenuItem
            className="sandbox-user"
            sx={{ color: "text.secondary" }}
            onClick={() => handleAddToSandbox(id)}
          >
            <ListItemIcon>
              <Iconify icon="mingcute:user-add-fill" width={24} height={24} />
            </ListItemIcon>
            <ListItemText
              primary="Add user to sandbox"
              primaryTypographyProps={{ variant: "body2" }}
            />
          </MenuItem>
        )}
      </Menu>
    </>
  );
}
