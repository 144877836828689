import * as React from 'react';
import PropTypes from "prop-types";
import { useEffect } from "react";
import { Link as RouterLink, useLocation, useNavigate } from "react-router-dom";
// material
import { styled } from "@mui/material/styles";
import {
  Box,
  Link,
  Button,
  Drawer, 
  Typography,
  Avatar,
  Stack,
  Divider
} from "@mui/material";
import { playstore } from "src/assets";

// hooks
import useResponsive from "../../hooks/useResponsive";
import { useAppContext } from "src/hooks";
// components
import Logo from "../../components/Logo";
import Scrollbar from "../../components/Scrollbar";
import NavSection from "../../components/NavSection";
import Switch from '@mui/material/Switch';
//
import navConfig from "./NavConfig";
import { getSandboxModeDetail, sandboxModeChange } from 'src/DAL/settings';
import { useSnackbar } from "notistack";

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 250;

const RootStyle = styled("div")(({ theme }) => ({
  [theme.breakpoints.up("lg")]: {
    flexShrink: 0,
    width: DRAWER_WIDTH,
  },
}));

const AccountStyle = styled("div")(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  padding: theme.spacing(2, 2.5),
  borderRadius: Number(theme.shape.borderRadius) * 1.5,
  backgroundColor: theme.palette.grey[500_12],
}));
const LogoutContainer = styled("div")(({ theme }) => ({
  paddingInline: theme.spacing(2.5),
  paddingBlock: theme.spacing(1),
  marginTop: 15,
  backgroundColor: "#ffff",
  position: "absolute",
  width: "100%",
  bottom: "0",
}));

// ----------------------------------------------------------------------

DashboardSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func,
};

export default function DashboardSidebar({ isOpenSidebar, onCloseSidebar }) {

  const { _get_user_profile, checked, setChecked , setAction} = useAppContext();

  const { pathname } = useLocation();
  const navigate = useNavigate();
  const isDesktop = useResponsive("up", "lg");
  const profile = _get_user_profile();
  const { enqueueSnackbar } = useSnackbar();
console.log(checked,"asasasasas")
  const handleChange = (event) => {
    setChecked(event.target?.checked);
    _handle_submit_sandbox();
  };
  const handleLogout = () => {
    localStorage.clear();
    navigate("/login", { replace: true });
  };
  useEffect(() => {
    if (isOpenSidebar) {
      onCloseSidebar();
    }
  }, [pathname]);

  const _handle_submit_sandbox = async () => {
    const formData = new FormData();
   
    checked === true ? formData.append("app_mode","live") : formData.append("app_mode","sandbox")
console.log([...formData],"check sandbox mode")
    const response = await sandboxModeChange(formData)
    if(response.code===200){
      enqueueSnackbar(response.message, { variant: "success" });
    }
    else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  const sandboxDetail = async () => {
    const response = await getSandboxModeDetail();
    if(response.code===200){
      setAction(response.setting.report_action);
      if(response.setting.app_mode === "live")
      setChecked(false)
      else if(response.setting.app_mode === "sandbox"){
      setChecked(true)
      }
      console.log(response,"sandbox----")
    }
    else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
  }

  useEffect(() => {
    sandboxDetail();
  }, []);

  const renderContent = (
    <Scrollbar
      sx={{
        height: 1,
        "& .simplebar-content": {
          height: 1,
          display: "flex",
          flexDirection: "column",
        },
      }}
    >
      <Box
        sx={{
          px: 2.5,
          py: 1.5,
          display: "inline-flex",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        {/* <Logo /> */}
        <img
          src={playstore}
          width="100px"
          height="100px"
          style={{ borderRadius: "50px" }}
        />
      </Box>
      <Divider />
      <Box className='d-flex justify-content-between align-items-center ps-3 pe-2'>
        {checked === true ? <Typography color="primary" style={{fontWeight:600}}>Sandbox Mode On</Typography>:<Typography >Sandbox Mode Off</Typography>}
        <Switch
          checked={checked}
          onChange={handleChange}
          inputProps={{ "aria-label": "controlled" }}
        />
      </Box>
      <Divider className='mb-2'/>

      {/* <Box sx={{ mb: 5, mx: 2.5 }}>
        <Link underline='none' component={RouterLink} to='/profile'>
          <AccountStyle>
            {profile.image ? (
              <>
                <Avatar src={profile.image} alt='photoURL' />
              </>
            ) : (
              <>
                <Avatar sx={{ bgcolor: (theme) => theme.palette.primary.main }}>
                  {profile.first_name.charAt(0)}
                </Avatar>
              </>
            )}
            <Box sx={{ ml: 2 }}>
              <Typography variant='subtitle2' sx={{ color: 'text.primary' }}>
                {profile.first_name}
              </Typography>
            </Box>
          </AccountStyle>
        </Link>
      </Box> */}

      <NavSection navConfig={navConfig} />
    </Scrollbar>
  );

  return (
    <RootStyle>
      {!isDesktop && (
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH },
          }}
        >
          {renderContent}
          <LogoutContainer className="logout-box">
            <Button className="logout-btn" onClick={handleLogout} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}

      {isDesktop && (
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: {
              width: DRAWER_WIDTH,
              bgcolor: "background.default",
              borderRightStyle: "dashed",
            },
          }}
        >
          {renderContent}
          <LogoutContainer className="logout-box">
            <Button className="logout-btn" onClick={handleLogout} variant="contained" fullWidth>
              Logout
            </Button>
          </LogoutContainer>
        </Drawer>
      )}
    </RootStyle>
  );
}
