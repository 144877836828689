import React, { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { gettranslateTracks, translateTracks } from "src/DAL/tracks";
import {
  Drawer,
  TextField,
  Container,
  Typography,
  CircularProgress,
} from "@mui/material";
import { LoadingButton } from "@mui/lab";

function LanguageTrackDrawer({
  openEditDrawer,
  setOpenLanguageDrawer,
  editCategoryId,
  language,
  setTracks,
  is_Published,
}) {
  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [notificationTitle, setNotificationTitle] = useState("");
  const [notificationDescription, setNotificationDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [isPublished, setIsPublished] = useState(is_Published);
  const [showLoader, setShowLoader] = useState(false); // State to manage the loader

  useEffect(() => {
    if (openEditDrawer) {
      setShowLoader(true); // Show loader when drawer is opened
      setName("");
      setDescription("");
      setNotificationTitle("");
      setNotificationDescription("");
      setIsPublished(is_Published);

      const fetchTranslatedTrack = async () => {
        try {
          const response = await gettranslateTracks(editCategoryId, language);
          if (response.code === 200) {
            const {
              name,
              description,
              notification_title,
              notification_description,
              is_published,
            } = response.category;
            setName(name);
            setIsPublished(is_published);
            setDescription(description);
            setNotificationTitle(notification_title);
            setNotificationDescription(notification_description);
          }
        } catch (error) {
          console.error(error);
          enqueueSnackbar("Error occurred while fetching translated track", {
            variant: "error",
          });
        } finally {
          setShowLoader(false); // Hide loader after API call completes
        }
      };

      fetchTranslatedTrack();
    }
  }, [openEditDrawer, editCategoryId, language, enqueueSnackbar, is_Published]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!name || !language) {
      enqueueSnackbar("Name and language are required", { variant: "error" });
      return;
    }
    const requestBody = {
      name,
      description,
      notification_title: notificationTitle,
      notification_description: notificationDescription,
      language,
    };
    setLoading(true);
    try {
      const response = await translateTracks(editCategoryId, requestBody);
      if (response.code === 200) {
        setTracks((old) =>
          old.map((item) => {
            if (item._id === editCategoryId) {
              item.translated_record = response.category.translated_record;
            }
            return item;
          })
        );

        enqueueSnackbar(response.message, { variant: "success" });
        setOpenLanguageDrawer(false);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.error(error);
      enqueueSnackbar("Error occurred while updating the track", {
        variant: "error",
      });
    } finally {
      setLoading(false);
    }
  };

  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openEditDrawer}
      onClose={() => setOpenLanguageDrawer(false)}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography
          variant="h4"
          sx={{ mt: -5, mb: 5, textTransform: "capitalize" }}
        >
          {`Track in ${language}`}
        </Typography>

        {showLoader && (
          <Container sx={{ display: "flex", justifyContent: "center", my: 5 }}>
            <div
              style={{
                height: "100vh",
                display: "flex",
                justifyContent: "center",
                marginTop: "200px ",
              }}
            >
              <CircularProgress />
            </div>
          </Container>
        )}

        {!showLoader && (
          <form onSubmit={handleSubmit}>
            <TextField
              value={name}
              onChange={(e) => setName(e.target.value)}
              id="outlined-basic"
              label={`Name in ${language}`}
              variant="outlined"
              fullWidth
              required
              sx={{ mb: 2 }}
            />
            <TextField
              value={description}
              required
              onChange={(e) => setDescription(e.target.value)}
              id="outlined-description"
              label={`Description in ${language}`}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              multiline
              rows={4}
            />
            <TextField
              value={notificationTitle}
              required
              onChange={(e) => setNotificationTitle(e.target.value)}
              id="outlined-notification-title"
              label={`Notification Title in ${language}`}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              disabled={Boolean(isPublished)}
            />
            <TextField
              value={notificationDescription}
              required
              onChange={(e) => setNotificationDescription(e.target.value)}
              id="outlined-notification-description"
              label={`Notification Description in ${language}`}
              variant="outlined"
              fullWidth
              sx={{ mb: 2 }}
              disabled={Boolean(isPublished)}
              multiline
              rows={4}
            />
            <LoadingButton
              sx={{ marginTop: "20px" }}
              variant="contained"
              type="submit"
              loading={loading}
            >
              Save
            </LoadingButton>
          </form>
        )}
      </Container>
    </Drawer>
  );
}

export default LanguageTrackDrawer;
