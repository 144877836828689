import { filter } from "lodash";
import { useState, useEffect } from "react";
import { useSnackbar } from "notistack";
import { getCategories, deletStoryeCategory } from "src/DAL/story_category";
import StoryLanguageDrawer from "../story/components/StoryLangugeDrawer";
import { s3baseUrl } from "src/config/config";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";

import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  Checkbox,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
} from "@mui/material";
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import AddCategoryDrawer from "./components/AddCategoryDrawer";
import EditCategoryDrawer from "./components/EditCategoryDrawer";
import moment from "moment";
const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "story_count", label: "Story Count", alignRight: false },
  {
    id: "languages",
    label: "Supported Languages",
    alignRight: false,
    alignCenter: true,
  },

  { id: "status", label: "Status", alignRight: false },
  { id: "date", label: "Date", alignRight: false, alignCenter: true },
  { id: "", label: "Action", alignRight: true },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(orderBy) {
  return (a, b) => -descendingComparator(a, b, orderBy);
}
export default function StoryCategories() {
  const { enqueueSnackbar } = useSnackbar();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [openLanguageDrawer, setOpenLanguageDrawer] = useState(false);

  const [categories, setCategories] = useState();
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [editCategoryId, setEditCategoryId] = useState();
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [editIndex, setEditIndex] = useState();
  const [count, setCount] = useState(0);
  const [language, setLanguage] = useState("");

  function applySortFilter(array, comparator, query) {
    if (categories) {
      const stabilizedThis = array.map((el, index) => [el, index]);
      stabilizedThis.sort((a, b) => {
        // const order = comparator(a[0], b[0]);
        // if (order !== 0) return order;
        return a[1] - b[1];
      });
      if (query) {
        return filter(
          array,
          (_category) =>
            _category.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
        );
      }
      return stabilizedThis.map((el) => el[0]);
    }
  }

  //====================================================================

  const storyCategories = async () => {
    let data = {
      search: filterName,
    };
    const response = await getCategories(page, rowsPerPage, data);
    if (response.code === 200) {
      setCategories(response.story_categories);
      setCount(response.count);
    } else {
      enqueueSnackbar(response.message, { variant: "error" });
    }
    setOpenBackdrop(false);
  };
  const handleLanguage = (categoryId, language) => {
    setEditCategoryId(categoryId);
    setLanguage(language);
    setOpenLanguageDrawer(true);
  };

  useEffect(() => {
    storyCategories();
  }, [page, rowsPerPage, filterName]);

  useEffect(() => {
    setCategories([]);
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  const handleEdit = () => {
    setOpenEditDrawer(true);
  };

  const handleAdd = () => {
    setOpenAddDrawer(true);
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deletStoryeCategory(id);
      if (response.code === 200) {
        hideDeletedDataWithoutApi(index, categories, setCategories);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };

  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - categories.length) : 0;

  const filteredCategories = categories
    ? applySortFilter(categories, getComparator(orderBy), filterName)
    : [];
  useEffect(() => {
    setPage(0);
  }, [filterName]);
  const isCategoryNotFound = categories?.length === 0;

  return (
    <Page title="Story Categories">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      <EditCategoryDrawer
        categories={categories}
        index={editIndex}
        setCategories={setCategories}
        showEditedDataWithoutApi={showEditedDataWithoutApi}
        openEditDrawer={openEditDrawer}
        setOpenEditDrawer={setOpenEditDrawer}
        editCategoryId={editCategoryId}
        tracksCategories={storyCategories}
      />
      <AddCategoryDrawer
        categories={categories}
        setCategories={setCategories}
        showAddedDataWithoutApi={showAddedDataWithoutApi}
        openAddDrawer={openAddDrawer}
        setOpenAddDrawer={setOpenAddDrawer}
        tracksCategories={storyCategories}
      />
      <StoryLanguageDrawer
        openEditDrawer={openLanguageDrawer}
        setOpenLanguageDrawer={setOpenLanguageDrawer}
        editCategoryId={editCategoryId}
        language={language}
        setCategories={setCategories}
      />

      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            New Category
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Story Categories"
          />
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {categories &&
                    categories
                      // .slice(
                      //   page * rowsPerPage,
                      //   page * rowsPerPage + rowsPerPage
                      // )
                      .map((row, index) => {
                        const {
                          _id,
                          title,
                          status,
                          image,
                          trackCount,
                          story_count,
                          is_default,
                          createdAt,
                          languages,
                          translated_record,
                        } = row;

                        return (
                          <TableRow
                            hover
                            key={_id}
                            tabIndex={-1}
                            role="checkbox"
                          >
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {page * rowsPerPage + index + 1}
                              </Typography>
                            </TableCell>
                            <TableCell>
                              <Stack
                                direction="row"
                                alignItems="center"
                                spacing={2}
                              >
                                <Avatar alt={title} src={s3baseUrl + image} />
                              </Stack>
                            </TableCell>
                            <TableCell>
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </TableCell>
                            <TableCell align="left">{story_count}</TableCell>
                            <TableCell>
                              <div
                                className="tag"
                                style={{
                                  display: "flex",
                                  flexDirection: "column",
                                  alignItems: "center",
                                }}
                              >
                                <ul>
                                  {languages.map((language, index) => {
                                    let data_exists = translated_record?.find(
                                      (data) => data.language === language
                                    );
                                    return (
                                      <>
                                        <li
                                          className="language-item"
                                          key={index}
                                          onClick={() =>
                                            handleLanguage(_id, language)
                                          }
                                        >
                                          <div
                                            className={`exists-data ${data_exists ? "selected" : ""}`}
                                          ></div>
                                          {language}
                                        </li>
                                      </>
                                    );
                                  })}
                                </ul>
                              </div>
                            </TableCell>

                            {/* <TableCell align='left'>{company}</TableCell>
                          <TableCell align='left'>{role}</TableCell>
                          <TableCell align='left'>{isVerified ? 'Yes' : 'No'}</TableCell> */}
                            <TableCell align="left">
                              <Label
                                variant="outlined"
                                color={
                                  (status === true && "success") || "error"
                                }
                              >
                                {status === true ? "Active" : "Inactive"}
                              </Label>
                            </TableCell>
                            <TableCell align="center">
                              <Typography variant="subtitle2" noWrap>
                                {moment(createdAt).format("DD MMM YYYY")}
                              </Typography>
                            </TableCell>
                            <TableCell align="right">
                              <MoreMenu
                                id={_id}
                                index={index}
                                handleDelete={handleDelete}
                                handleEdit={handleEdit}
                                setEditId={setEditCategoryId}
                                setLanguage={languages}
                                setEditIndex={setEditIndex}
                                title="Category"
                                isDefault={is_default}
                                handleLanguage={handleLanguage}
                              />
                            </TableCell>
                          </TableRow>
                        );
                      })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {isCategoryNotFound && (
                  <TableBody>
                    <TableRow>
                      <TableCell align="center" colSpan={6} sx={{ py: 3 }}>
                        <SearchNotFound searchQuery={filterName} />
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
      </Container>
    </Page>
  );
}
