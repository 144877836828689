import React, { useState, useEffect } from "react";
import { Page } from "src/components";
import { useSnackbar } from "notistack";
import { NotificationLanguages } from "src/constants";
import {
  Container,
  Typography,
  Grid,
  TextField,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Card,
  CardContent,
  CardActions,
  Alert,
} from "@mui/material";
import LoadingButton from "@mui/lab/LoadingButton";
import { push_notifications } from "src/DAL/push_notifications";

function PushNotifications() {
  const { enqueueSnackbar } = useSnackbar();
  const [loading, setLoading] = useState(false);
  const [languages, setLanguages] = useState(["english"]);

  // Initialize state for each language's title and description
  const initialData = NotificationLanguages.reduce((acc, lang) => {
    acc[lang] = { notification_title: "", notification_description: "" };
    return acc;
  }, {});

  const [notificationData, setNotificationData] = useState(initialData);

  const resetNotificationData = () => {
    setNotificationData(initialData);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (languages.length === 0) {
      enqueueSnackbar("Kindly choose at least one language", {
        variant: "error",
      });
      return;
    }
    setLoading(true);
    try {
      // Create an array of notifications
      const notifications = languages.map((lang) => ({
        language: lang,
        notification_title: notificationData[lang].notification_title,
        notification_description:
          notificationData[lang].notification_description,
      }));

      const result = await push_notifications({ notification: notifications });

      if (result.code === 200) {
        enqueueSnackbar(result.message, { variant: "success" });
        resetNotificationData(); // Reset fields after successful submission
      } else {
        enqueueSnackbar(result.message, { variant: "error" });
      }
    } catch (e) {
      console.error("Error sending notifications:", e);
    } finally {
      setLoading(false);
    }
  };

  const handleTitleChange = (event, language) => {
    const { value } = event.target;
    setNotificationData((prevData) => ({
      ...prevData,
      [language]: { ...prevData[language], notification_title: value },
    }));
  };

  const handleDescriptionChange = (event, language) => {
    const { value } = event.target;
    setNotificationData((prevData) => ({
      ...prevData,
      [language]: { ...prevData[language], notification_description: value },
    }));
  };

  const handleChange = (event) => {
    const value = event.target.value;
    if (value.length === 0) {
      enqueueSnackbar("Kindly choose at least one language", {
        variant: "error",
      });
    } else if (value.length === 1 && value[0] === "English") {
      enqueueSnackbar(
        "Cannot unselect 'English'. Please select at least one language.",
        { variant: "error" }
      );
      setLanguages(["English"]); // Revert to English if it's the only language selected
    } else {
      setLanguages(value);
    }
  };

  return (
    <Page title="Push Notification">
      <Container maxWidth="xl">
        <Typography variant="h4" sx={{ mb: 5 }}>
          Send Notification
        </Typography>
        <Alert severity="info" color="primary" sx={{ mb: 3, width: "40%" }}>
          <small>Default Language is English</small>
        </Alert>

        <Grid container spacing={3}>
          <Grid item xs={12} sm={6}>
            <FormControl fullWidth>
              <InputLabel id="language-select-label">Languages</InputLabel>
              <Select
                sx={{ textTransform: "capitalize" }}
                labelId="language-select-label"
                id="language-select"
                multiple
                value={languages}
                label="Languages"
                onChange={handleChange}
                renderValue={(selected) => selected.join(", ")}
                InputLabelProps={{
                  style: { textTransform: "capitalize" },
                }}
              >
                {NotificationLanguages.map((lang, index) => (
                  <MenuItem
                    sx={{ textTransform: "capitalize" }}
                    key={index}
                    value={lang}
                  >
                    {lang}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <form onSubmit={handleSubmit}>
          {languages.map((language) => (
            <Card key={language} sx={{ mt: 3 }}>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        required
                        value={
                          notificationData[language]?.notification_title || ""
                        }
                        id={`outlined-basic-${language}`}
                        label={`Title in ${language}`}
                        variant="outlined"
                        onChange={(e) => handleTitleChange(e, language)}
                        InputLabelProps={{
                          style: { textTransform: "capitalize" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl fullWidth>
                      <TextField
                        onChange={(e) => handleDescriptionChange(e, language)}
                        required
                        id={`outlined-multiline-static-${language}`}
                        label={`Description in ${language}`}
                        multiline
                        rows={4}
                        value={
                          notificationData[language]
                            ?.notification_description || ""
                        }
                        InputLabelProps={{
                          style: { textTransform: "capitalize" },
                        }}
                      />
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          ))}
          <CardActions>
            <LoadingButton
              sx={{ width: 200, mt: 3 }}
              loading={loading}
              type="submit"
              variant="contained"
            >
              Send
            </LoadingButton>
          </CardActions>
        </form>
      </Container>
    </Page>
  );
}

export default PushNotifications;
