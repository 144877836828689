import React, { useState } from "react";
import PhotoCamera from "@mui/icons-material/PhotoCamera";
import AudioFileIcon from "@mui/icons-material/AudioFile";
import { uploadImage, uploadAudio } from "src/DAL/uploadFiles";
import { addTrack } from "src/DAL/tracks";
import { useSnackbar } from "notistack";
import { Iconify } from "src/components";
import DatePickerField from "src/components/Datepicker";
import { supportedLanguages } from "src/constants";
import { getActive_CatogeriesApi } from "src/DAL/category";
import {
  Drawer,
  Button,
  TextField,
  Container,
  Select,
  MenuItem,
  InputLabel,
  FormControl,
  Typography,
  Grid,
  Alert,
  AlertTitle,
} from "@mui/material";
const ITEM_HEIGHT = 40;
const ITEM_PADDING_TOP = 5;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 200,
    },
  },
};
import LoadingButton from "@mui/lab/LoadingButton";
import { useEffect } from "react";
import { useAppContext } from "src/hooks";
import moment from "moment";
import { Language, MonetizationOn } from "@mui/icons-material";
function AddTrackDrawer({
  openAddDrawer,
  setOpenAddDrawer,
  categories,
  showAddedDataWithoutApi,
  tracks,
  setTracks,
  count,
  setCount,
  funcTracks,
}) {
  const { checked } = useAppContext();

  const { enqueueSnackbar } = useSnackbar();
  const [name, setName] = useState();
  const [status, setStatus] = useState(true);
  const [author, setAuthor] = useState();
  const [selectedImage, setSelectedImage] = useState();
  const [selectedAudio, setSelectedAudio] = useState();
  const [selectedCategories, setSelectedCategories] = useState([]);
  const [description, setDescription] = useState();
  const [language, setLanguage] = useState([]);
  const [activeCategories, setActiveCategories] = useState([]);

  const [loading, setLoading] = useState(false);
  const [imagePreview, setImagePreview] = useState();
  const [isPaid, setIsPaid] = useState(false);
  const [notificationTitle, setNotificationTitle] = useState();
  const [notificationDescription, setNotificationDescription] = useState();
  const [notificationDate, setNotificationDate] = useState(
    new Date(moment().add(5, "minutes"))
  );
  const [segment, setSegment] = useState("day");

  //=============== The function that submits the form======================
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (selectedCategories.length > 0) {
      setLoading(true);

      //the select element only returns the array of id, here we convert that array into the format that is accepted by our API
      let newCat,
        newSelectedCategories = [];
      selectedCategories.map((category) => {
        newCat = { _id: category };
        newSelectedCategories = [...newSelectedCategories, newCat];
      });
      //------------------------------------------------

      // here we convert our image and audio intoform data because it is the only way we post our files tothe API
      const formDataImage = new FormData();
      const formDataAudio = new FormData();
      formDataImage.append("image", selectedImage);
      formDataImage.append("module", "track");
      formDataAudio.append("audio", selectedAudio);
      try {
        // first we try to upload image if it is successfull, then we will move next.
        const responseImage = await uploadImage(formDataImage);
        if (responseImage.code === 200) {
          //since our image is uloaded, now we shall try to upload audio
          const responseAudio = await uploadAudio(formDataAudio);
          if (responseAudio.code === 200) {
            try {
              // after uploading the audio, we will extract the required values from responses of...
              //... image and audio api and send our data to the function that will hit the API.
              notificationTitle
                ? notificationTitle
                : setNotificationTitle(name);
              notificationDescription
                ? notificationDescription
                : setNotificationDescription(description);
              const res = await addTrack({
                name,
                status,
                author,
                category_id: newSelectedCategories,
                audio: responseAudio.file.path,
                duration: responseAudio.file.duration,
                images: responseImage.paths,
                description,
                languages: language,
                notification_title: notificationTitle,
                notification_description: notificationDescription,
                date: notificationDate,
                is_locked: isPaid,
                day_segment: segment,
              });
              if (res.code === 200) {
                //if the data is successfully uploaded, we will change the data on screen without hitting the get API again.
                funcTracks();
                // showAddedDataWithoutApi(res.Track, tracks, setTracks);
                // let data= tracks
                // data.splice(res.Track.order-1,0,res.Track)
                // setTracks(data.map((row,index)=>{
                //   return{...row, order:index+1}
                // }))
                setCount(count + 1);
                setNotificationDate("");
                setNotificationDescription("");
                setNotificationTitle("");
                handleClose();
                enqueueSnackbar(res.message, { variant: "success" });
              } else {
                enqueueSnackbar(res.message, { variant: "error" });
              }
              setOpenAddDrawer(false);
            } catch (error) {
              console.log(error);
              enqueueSnackbar("Could not add Track, Please try again.", {
                variant: "error",
              });
            }
          } else {
            enqueueSnackbar(responseAudio.message, { variant: "error" });
          }
        } else {
          enqueueSnackbar(responseImage.message, { variant: "error" });
        }
      } catch (error) {
        console.log(error);
        enqueueSnackbar("Could not Upload File(s), Please try again.", {
          variant: "error",
        });
      }
      setLoading(false);
    } else {
      enqueueSnackbar("Please select atleast 1 category", { variant: "error" });
    }
  };
  //==========================================================================

  // function that will hit at closing the drawer

  const handleClose = () => {
    setOpenAddDrawer(false);
    setName();
    setStatus(true);
    setSelectedImage();
    setSelectedAudio();
    setDescription();
    setSelectedCategories([]);
    setLanguage([]);

    setLoading(false);
    setImagePreview();
    setNotificationDate("");
  };

  useEffect(() => {
    if (openAddDrawer) {
      setNotificationDate(new Date(moment().add(5, "minutes")));
    }
  }, [openAddDrawer]);

  //==========================================================================

  // function that will preview the image before uploading

  useEffect(() => {
    if (selectedImage) {
      console.log(selectedImage);
      setImagePreview(URL.createObjectURL(selectedImage));
      console.log(imagePreview);
    }
  }, [selectedImage]);

  //===================================================================
  return (
    <Drawer
      variant="temporary"
      anchor="right"
      open={openAddDrawer}
      onClose={handleClose}
    >
      <Container sx={{ my: 10, width: 390 }}>
        <Typography variant="h4" sx={{ mt: -5, mb: 5 }}>
          Add new Track
        </Typography>
        <div className="mb-3">
          {checked && checked === true ? (
            <Alert severity="info" color="primary">
              <AlertTitle>Sandbox mode is on</AlertTitle>
              Notification scheduling is restricted to{" "}
              <strong>sandbox users</strong> only.
            </Alert>
          ) : (
            <Alert severity="error">
              <AlertTitle>Sandbox mode is off</AlertTitle>
              Notifications will be scheduled for <strong>all users.</strong>.
            </Alert>
          )}
        </div>
        <Alert severity="info" color="primary" sx={{ mb: 3 }}>
          <small>Default Language is English</small>
        </Alert>

        <form onSubmit={handleSubmit}>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setName(e.target.value)}
              required
              id="outlined-basic"
              label="Name"
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setDescription(e.target.value)}
              required
              id="outlined-multiline-static"
              label="Description"
              multiline
              rows={4}
              defaultValue=""
              //   variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setAuthor(e.target.value)}
              required
              id="outlined-basic"
              label="Author"
              variant="outlined"
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Categories</InputLabel>
            <Select
              required
              multiple
              label="Categories"
              value={selectedCategories}
              onChange={(e) => {
                setSelectedCategories(e.target.value);
              }}
              MenuProps={MenuProps}
            >
              {categories.length === 0 && (
                <MenuItem disabled>
                  <em>No Category found</em>
                </MenuItem>
              )}
              {categories &&
                categories.map((category) => {
                  return (
                    <MenuItem key={category._id} value={category._id}>
                      {category.name}
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Languages</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Languages"
              value={language}
              className="text-capitalize"
              onChange={(e) => setLanguage(e.target.value)}
              multiple
            >
              {supportedLanguages?.map((name) => {
                return (
                  <MenuItem className="text-capitalize" value={name}>
                    {name}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>

          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Paid</InputLabel>
            <Select
              required
              label="Paid"
              value={isPaid}
              onChange={(e) => setIsPaid(e.target.value)}
            >
              <MenuItem value={true}>
                <Iconify icon="ic:outline-attach-money" color="green" /> Yes
              </MenuItem>
              <MenuItem value={false}>
                <Iconify icon="ic:outline-money-off-csred" color="red" /> No
              </MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Type</InputLabel>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              label="Segment"
              value={segment}
              onChange={(e) => setSegment(e.target.value)}
            >
              <MenuItem value="day">Morning</MenuItem>
              <MenuItem value="evening">Evening</MenuItem>
              <MenuItem value="night">Night</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <InputLabel id="demo-simple-select-label">Status</InputLabel>
            <Select
              required
              label="Status"
              value={status}
              onChange={(e) => setStatus(e.target.value)}
            >
              <MenuItem value={true}>Active</MenuItem>
              <MenuItem value={false}>Inactive</MenuItem>
            </Select>
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setNotificationTitle(e.target.value)}
              id="outlined-basic"
              label="Notification Title"
              variant="outlined"
              value={notificationTitle}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <TextField
              onChange={(e) => setNotificationDescription(e.target.value)}
              id="outlined-multiline-static"
              label="Notification Description"
              multiline
              rows={4}
              defaultValue=""
              value={notificationDescription}
            />
          </FormControl>
          <FormControl fullWidth sx={{ mb: 2 }}>
            <DatePickerField
              label="Schedule On*"
              value={notificationDate}
              onChange={(date) => setNotificationDate(date)}
            />
          </FormControl>
          <Grid container spacing={2}>
            <Grid item xs={imagePreview ? 8 : 12}>
              <FormControl fullWidth sx={{ mb: 2, display: "block" }}>
                <Button
                  variant="contained"
                  //   sx={{ width: "50%" }}
                  component="label"
                >
                  {!selectedImage && (
                    <>
                      Select Image <PhotoCamera sx={{ ml: 1 }} />
                    </>
                  )}
                  {selectedImage && (
                    <>
                      <PhotoCamera sx={{ mr: 1 }} /> {selectedImage.name}
                    </>
                  )}
                  <input
                    hidden
                    name="image"
                    accept="image/jpeg, image/png , image/jpg , image/webp , image/HEIC"
                    type="file"
                    onChange={(e) => setSelectedImage(e.target.files[0])}
                  />
                </Button>
              </FormControl>
              <small>
                Recommended image size (1080x1920) and file types (jpg,png)
              </small>
            </Grid>
            {imagePreview && (
              <Grid item xs={4} sx={{marginTop:"10px"}}>
                <img src={imagePreview} alt="image" width="100" height="auto" />
              </Grid>
            )}
          </Grid>
          <FormControl fullWidth sx={{ mt: 2, mb: 2, display: "block" }}>
            <Button aria-required variant="contained" component="label">
              {!selectedAudio && (
                <>
                  Select Track <AudioFileIcon sx={{ ml: 1 }} />
                </>
              )}
              {selectedAudio && (
                <>
                  <AudioFileIcon sx={{ mr: 1 }} /> {selectedAudio.name}
                </>
              )}
              <input
                hidden
                name="audio"
                accept="audio/mp3"
                type="file"
                onChange={(e) => {
                  if (e.target.files[0].size > 10485760) {
                    enqueueSnackbar("Maximum size limit for track is 10 mb", {
                      variant: "error",
                    });
                    e.target.value = null;
                  } else setSelectedAudio(e.target.files[0]);
                }}
              />
            </Button>
          </FormControl>
          <LoadingButton loading={loading} type="submit" variant="contained">
            Save
          </LoadingButton>
        </form>
      </Container>
    </Drawer>
  );
}

export default AddTrackDrawer;
