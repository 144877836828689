import { Container, Typography, Grid, TextField, Button } from '@mui/material';
import React, {useState} from 'react';
import { Page } from 'src/components';
import { useAppContext } from "src/hooks";

const Settings = () => {
  const { _get_user_profile } = useAppContext();
  const profile = _get_user_profile();
  return (
    <Page title='Settings'>
      <Container maxWidth='xl'>
        <Typography variant='h4' gutterBottom>
          Settings
        </Typography>
      </Container>
      <Container sx={{ my: 10 }}>
        <Grid container spacing={5}>
          <Grid item xs={2}>
            <Typography variant="h6">First Name:</Typography>
          </Grid>
          <Grid item xs={4}>
          <TextField id="outlined-basic" label="First Name" variant="outlined" value={profile.first_name} />
          </Grid>
        </Grid>
        <Grid container spacing={5} sx={{my:1}}>
          <Grid item xs={2}>
            <Typography variant="h6">Last Name:</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField id="outlined-basic" label="Last Name" variant="outlined" value={profile.last_name} />
          </Grid>
        </Grid>
        <Button variant='contained' sx={{mt:10, px:10}}>
              Update
            </Button>
      </Container>
    </Page>
  );
};

export default Settings;
