import { useLocation } from "react-router-dom";
import { filter } from "lodash";
import { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { supportedLanguages } from "src/constants";
import { useSnackbar } from "notistack";
import { getAllCategories } from "src/DAL/category";
import { getActiveCategories, getCategories } from "src/DAL/story_category";
import StoryDarwerLanguagesLang from "./StoryLanguagesDrawerLang";
import { getTracks } from "src/DAL/tracks";
import {
  getStories,
  deleteTrack,
  getTrackDetail,
  deleteStory,
  filterStory,
} from "src/DAL/story";
import { addStory } from "src/DAL/story";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  DialogContentText,
} from "@mui/material";

import { s3baseUrl } from "src/config/config";
// import CircularProgress from '@mui/material/CircularProgress';
import CustomConfirmation from "src/components/CustomConfirmation";
import {
  showAddedDataWithoutApi,
  showEditedDataWithoutApi,
  hideDeletedDataWithoutApi,
} from "src/DAL/updateWithoutApi";
import {
  Card,
  Table,
  Stack,
  Avatar,
  Button,
  TableRow,
  TableBody,
  TableCell,
  Container,
  Typography,
  TableContainer,
  TablePagination,
  Backdrop,
  CircularProgress,
  Tooltip,
  Divider,
} from "@mui/material";
import {
  Page,
  Label,
  Scrollbar,
  Iconify,
  SearchNotFound,
} from "src/components";
import ListHead from "src/components/ListHead";
import ListToolbar from "src/components/ListToolbar";
import MoreMenu from "src/components/MoreMenu";
import AddTrackDrawer from "./components/AddStoryDrawer";
// modal
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import CloseIcon from "@mui/icons-material/Close";
import AudioPlayer from "react-h5-audio-player";
import "react-h5-audio-player/lib/styles.css";
import moment from "moment";
import { LoadingButton } from "@mui/lab";
import FilterTrackDrawer from "./components/FilterStoryDrawer";
import EditStoryDrawer from "./components/EditStoryDrawer";
const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  border: "1px solid #ccc",
  boxShadow: 24,
  p: 2,
};

const TABLE_HEAD = [
  { id: "count", label: "#", alignRight: false },
  { id: "image", label: "Image", alignRight: false },
  { id: "name", label: "Name", alignRight: false },
  { id: "description", label: "Description", alignRight: false },
  { id: "views", label: "Views", alignRight: false },
  { id: "cateories", label: "Category", alignRight: false },
  { id: "story", label: "Story", alignRight: false },
  {
    id: "languages",
    label: "Supported Languages",
    alignRight: false,
    alignCenter: true,
  },

  { id: "status", label: "Status", alignRight: false },
  { id: "schedule-status", label: "Schedule Status", alignRight: false },

  { id: "notifyDate", label: "Schedule Date", alignRight: false },
  { id: "is_sandbox", label: "Sandbox", alignRight: false },

  { id: "", label: "Action", alignRight: false },
];
function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return (a, b) => -descendingComparator(a, b, orderBy);
}
export default function Tracks() {
  const { enqueueSnackbar } = useSnackbar();
  const location = useLocation();
  const [openBackdrop, setOpenBackdrop] = useState(true);
  const [categories, setCategories] = useState();
  const [tracks, setTracks] = useState();
  const [count, setCount] = useState();
  const [openAddDrawer, setOpenAddDrawer] = useState(false);
  const [openFilterDrawer, setOpenFilterDrawer] = useState(false);
  const [editTrackId, setEditTrackId] = useState();
  const [page, setPage] = useState(0);
  const [filterName, setFilterName] = useState("");
  const [rowsPerPage, setRowsPerPage] = useState(50);
  const [order, setOrder] = useState();
  const [orderBy, setOrderBy] = useState(order);
  const [editIndex, setEditIndex] = useState();
  const [openDelete, setOpenDelete] = useState(false);
  const [isLoadingBtn, setIsLoadingBtn] = useState(false);
  const [loadingBtn, setLoadinBtn] = useState(false);
  const [filterCounts, setFilterCounts] = useState("");
  const [openEditDrawer, setOpenEditDrawer] = useState(false);
  const [selectedStoryId, setSelectedStoryId] = useState(null);
  const [catogeries, setcatogeries] = useState([]);
  const [mediatypeFilter, setmediatypeFilter] = useState("");
  const [fromdate, setfromdate] = useState("");
  const [selectedObject, setSelectedObject] = useState({});
  const [endDate, setEndDate] = useState("");
  const [language, setLanguage] = useState("");
  const [openLanguageDrawer, setOpenLanguageDrawer] = useState(false);

  const [status, setstatus] = useState("");
  const [categoriesfilter, setcategoriesfilter] = useState("");
  const [isPublished, setIsPublished] = useState(false);

  const [filterTracks, setFilterTracks] = useState(
    localStorage.getItem("filter")
      ? JSON.parse(localStorage.getItem("filter"))
      : {
          story_categories: categoriesfilter,
          media_type: mediatypeFilter,
          status: status,
          start_date: fromdate,
          end_date: endDate,
        }
  );

  // modal
  const [open, setOpen] = useState(false);
  const [imagePath, setImagePath] = useState("");
  const handleOpen = (image) => {
    if (image) {
      setImagePath(image);
      setOpen(true);
    }
  };
  const handleClose = () => setOpen(false);

  // --------------------------------------------------------------------------------

  function applySortFilter(array, comparator, query) {
    const stabilizedThis = array?.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
      const order = comparator(a[0], b[0]);
      if (order !== 0) return order;
      return a[1] - b[1];
    });
    if (query) {
      return filter(
        array,
        (_track) =>
          _track.title.toLowerCase().indexOf(query.toLowerCase()) !== -1
      );
    }
    return stabilizedThis?.map((el) => el[0]);
  }

  //categories will be used while adding or editing the Story
  const funcCategories = async () => {
    const response = await getActiveCategories();
    console.log(response, "response active categories....");
    if (response.code === 200) {
      console.log(response.story_categories);
      setCategories(response.story_categories);
    }
  };

  //  will be used while displaying the listing
  const funcTracks = async () => {
    try {
      setLoadinBtn(true);
      let data = {
        search: filterName,
      };
      const response = await getStories(page, rowsPerPage, data);
      console.log(response, "Response for stories");
      console.log(filterTracks, "filtersss");
      if (response.code === 200) {
        // setIsLoadingBtn(false);
        localStorage.setItem("filter", JSON.stringify(filterTracks));
        setOpenFilterDrawer(false);
        setTracks(response.stories);
        setCount(response.count);
        setFilterCounts(
          Object.values(filterTracks).filter((val) => val !== "").length
        );
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadinBtn(false);
      setOpenBackdrop(false);
    }
  };

  const filterStoriesApi = async (data) => {
    try {
      setLoadinBtn(true);
      console.log(data, "filterss request");
      const response = await filterStory(data);
      console.log(response, "filterss response");
      if (response.code === 200) {
        // setIsLoadingBtn(false);
        localStorage.setItem("filter", JSON.stringify(filterTracks));
        setOpenFilterDrawer(false);
        setTracks(response.stories);
        setFilterCounts(
          Object.values(filterTracks).filter((val) => val !== "").length
        );
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setLoadinBtn(false);
      setOpenBackdrop(false);
    }
  };
  //duplicate this for clear filter
  const funcFilterClearTracks = async () => {
    try {
      setIsLoadingBtn(true);
      const response = await getStories(page, rowsPerPage, filterName, {
        story_categories: categoriesfilter,
        media_type: mediatypeFilter,
        status: status,
        start_date: fromdate,
        end_date: endDate,
        languages: [],
      });
      console.log(filterTracks, "filtersss");
      if (response.code === 200) {
        // setIsLoadingBtn(false);
        localStorage.setItem(
          "filter",
          JSON.stringify({
            story_categories: "",
            story_categories: categoriesfilter,
            media_type: mediatypeFilter,
            status: status,
            start_date: fromdate,
            end_date: endDate,
            languages: [],
          })
        );
        setFilterTracks({
          story_categories: categoriesfilter,
          media_type: mediatypeFilter,
          status: status,
          start_date: fromdate,
          end_date: endDate,
          languages: [],
        });
        // setOpenFilterDrawer(false);
        setTracks(response.stories);
        setCount(response.count);
        setFilterCounts(0);
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoadingBtn(false);
      //setOpenBackdrop(false);
    }
  };

  useEffect(() => {
    setOpenBackdrop(true);
    funcCategories();
  }, []);

  useEffect(() => {
    setTracks();
    setOpenBackdrop(true);
  }, [page, rowsPerPage]);

  useEffect(() => {
    setPage(0);
  }, [filterName]);
  const isStoryNotFound = getStories?.length === 0;

  useEffect(() => {
    // setOpenBackdrop(true);
    funcTracks();
  }, [page, rowsPerPage, filterName]);

  //=========================================================================
  const handleEdit = (storyId) => {
    setOpenEditDrawer(true);
  };

  const handleAdd = () => {
    setOpenAddDrawer(true);
  };

  const handleFilter = () => {
    setOpenFilterDrawer(true);
  };

  const handleFilterClose = () => {
    setOpenFilterDrawer(false);
    setFilterTracks({});
  };

  const handleFilterChange = (e) => {
    setFilterTracks((prev) => {
      return { ...prev, [e.target.name]: e.target.value };
    });
  };

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleFilterByName = (event) => {
    setFilterName(event.target.value);
  };
  const handleDelete = async (id, index) => {
    try {
      const response = await deleteStory(id);
      if (response.code === 200) {
        hideDeletedDataWithoutApi(index, tracks, setTracks);
        setCount(count - 1);
        enqueueSnackbar(response.message, { variant: "success" });
      } else {
        enqueueSnackbar(response.message, { variant: "error" });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - count) : 0;

  const filteredTracks = tracks
    ? applySortFilter(tracks, getComparator(order, orderBy), filterName)
    : [];
  const isTrackNotFound = filteredTracks.length === 0;

  const getScheduleStatus = (date) => {
    if (date) {
      const scheduleDate = new Date(date);
      const currentDate = new Date();

      if (scheduleDate.getTime() > currentDate.getTime()) {
        return "Schedule";
      } else if (scheduleDate.getTime() <= currentDate.getTime()) {
        return "Published";
      }
    } else {
      return "Published";
    }
  };

  const [openUp, setOpenUp] = useState(false);

  const handleClickOpenUp = (data) => {
    setSelectedObject(data);
    setOpenUp(true);
  };

  const handleCloseUp = () => {
    setSelectedObject({});
    setOpenUp(false);
  };

  console.log(selectedObject, "selectedObjectselectedObject");
  const handleLanguage = (trackId, language) => {
    setEditTrackId(trackId);
    setLanguage(language);
    setOpenLanguageDrawer(true);
  };

  return (
    <Page title="Stories">
      <Backdrop
        sx={{
          color: "#fff",
          zIndex: (theme) => theme.zIndex.drawer + 1,
        }}
        open={openBackdrop}
      >
        <CircularProgress color="inherit" />
      </Backdrop>
      {tracks && (
        <>
          <EditStoryDrawer
            openAddDrawer={openEditDrawer}
            setOpenAddDrawer={setOpenEditDrawer}
            categories={categories}
            showAddedDataWithoutApi={showAddedDataWithoutApi}
            tracks={tracks}
            setTracks={setTracks}
            count={count}
            setCount={setCount}
            funcTracks={funcTracks}
            storyId={selectedStoryId} // Pass the storyId prop here
          />
          <AddTrackDrawer
            tracks={tracks}
            setTracks={setTracks}
            count={count}
            setCount={setCount}
            showAddedDataWithoutApi={showAddedDataWithoutApi}
            openAddDrawer={openAddDrawer}
            setOpenAddDrawer={setOpenAddDrawer}
            categories={categories}
            funcTracks={funcTracks}
          />
          <FilterTrackDrawer
            openFilterDrawer={openFilterDrawer}
            setOpenFilterDrawer={setOpenFilterDrawer}
            categories={categories}
            handleFilterChange={handleFilterChange}
            filterStories={filterStoriesApi}
            handleFilterClose={handleFilterClose}
            funcTracks={funcTracks}
            funcFilterClearTracks={funcFilterClearTracks}
            isLoadingBtn={isLoadingBtn}
            loadingBtn={loadingBtn}
          />
          <StoryDarwerLanguagesLang
            openEditDrawer={openLanguageDrawer}
            setOpenLanguageDrawer={setOpenLanguageDrawer}
            editCategoryId={editTrackId}
            language={language}
            setTracks={setTracks}
            is_Published={isPublished}
          />
        </>
      )}
      <CustomConfirmation
        open={openDelete}
        setOpen={setOpenDelete}
        handleAgree={handleDelete}
        title="Track"
      />
      <Container maxWidth="xl">
        <Stack
          direction="row"
          alignItems="center"
          justifyContent="flex-end"
          my={1}
        >
          <LoadingButton
            className="me-3"
            variant="contained"
            loading={isLoadingBtn}
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={() => {
              funcFilterClearTracks();
            }}
          >
            Clear Filter
          </LoadingButton>
          <Button
            className="me-3"
            variant="contained"
            startIcon={<Iconify icon="mdi:filter" />}
            onClick={() => {
              handleFilter();
            }}
          >
            Filter
          </Button>
          <Button
            variant="contained"
            startIcon={<Iconify icon="eva:plus-fill" />}
            onClick={() => {
              handleAdd();
            }}
          >
            New Story
          </Button>
        </Stack>
        <Card
          sx={{ boxShadow: "rgba(99, 99, 99, 0.2) 0px 2px 8px 0px", mb: 1 }}
        >
          <ListToolbar
            filterName={filterName}
            onFilterName={handleFilterByName}
            moduleName="Stories"
            placeholder="Search  Story by name"
          />{" "}
          <Scrollbar>
            <TableContainer sx={{ minWidth: 800 }}>
              <Table>
                <ListHead headLabel={TABLE_HEAD} />
                <TableBody>
                  {tracks &&
                    tracks?.map((row, index) => {
                      const {
                        _id,
                        title,
                        languages,
                        status,
                        image,
                        video_url,
                        description,
                        date,
                        is_sandbox,
                        is_published,
                        views,
                        story_categories,
                        video_thumbnail,
                        translated_record,
                      } = row;
                      return (
                        <TableRow hover key={_id} tabIndex={-1} role="checkbox">
                          <TableCell>
                            <Typography variant="subtitle2" noWrap>
                              {page * rowsPerPage + index + 1}
                            </Typography>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 100 }}>
                            <Avatar
                              alt={name}
                              src={
                                row.story_type == "IMAGE"
                                  ? s3baseUrl + image
                                  : s3baseUrl + video_thumbnail
                              }
                              onClick={() => handleOpen(image)}
                            />
                          </TableCell>
                          <TableCell sx={{ maxWidth: 100 }}>
                            <Tooltip title={title}>
                              <Typography variant="subtitle2" noWrap>
                                {title}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 100 }}>
                            <Tooltip title={description}>
                              <Typography variant="subtitle2" noWrap>
                                {description}
                              </Typography>
                            </Tooltip>
                          </TableCell>
                          <TableCell sx={{ maxWidth: 100 }}>
                            <Tooltip title={description}>
                              <Typography
                                sx={{ marginLeft: "15px" }}
                                variant="subtitle2"
                                noWrap
                              >
                                {views}
                              </Typography>
                            </Tooltip>
                          </TableCell>

                          <TableCell>
                            <div
                              style={{
                                display: "flex",
                                alignItems: "center",
                                flexWrap: "wrap",
                              }}
                            >
                              {story_categories.length > 0 && (
                                <>
                                  <Label
                                    sx={{
                                      backgroundColor: "#f4fbea",
                                      padding: "8px 15px",
                                      borderRadius: "20px",
                                      border: "1px solid #87c326",
                                      height: "30px",
                                      marginRight: "5px",
                                    }}
                                  >
                                    <Typography
                                      sx={{ color: "#87c326" }}
                                      variant="subtitle2"
                                    >
                                      {`${story_categories[0].title} `}
                                    </Typography>
                                  </Label>
                                  {story_categories.length > 1 && (
                                    <Typography sx={{ color: "#87c326" }}>
                                      +{`${story_categories.length - 1} `}
                                    </Typography>
                                  )}
                                </>
                              )}
                            </div>
                          </TableCell>
                          <TableCell>
                            <Button
                              sx={{ color: "#87c326" }}
                              onClick={() => handleClickOpenUp(row)}
                            >
                              Preview
                            </Button>
                          </TableCell>
                          <TableCell>
                            <div
                              className="tag"
                              style={{
                                display: "flex",
                                flexDirection: "column",
                                alignItems: "center",
                              }}
                            >
                              <ul>
                                {languages.map((language, index) => {
                                  let data_exists = translated_record?.find(
                                    (data) => data.language === language
                                  );
                                  return (
                                    <>
                                      <li
                                        className="language-item"
                                        key={index}
                                        onClick={() => {
                                          setIsPublished(row.is_published);
                                          handleLanguage(_id, language);
                                        }}
                                      >
                                        <div
                                          className={`exists-data ${data_exists ? "selected" : ""}`}
                                        ></div>
                                        {language}
                                      </li>
                                    </>
                                  );
                                })}
                              </ul>
                            </div>
                          </TableCell>

                          <TableCell>
                            <Label
                              variant="outlined"
                              color={(status === true && "success") || "error"}
                            >
                              {status === true ? "Active" : "InActive"}
                            </Label>
                          </TableCell>
                          <TableCell>
                            <Label
                              variant="outlined"
                              color={
                                (is_published === true && "success") || "error"
                              }
                            >
                              {is_published === true ? "Published" : "Schedule"}
                            </Label>
                          </TableCell>

                          <TableCell sx={{ maxWidth: 200 }}>
                            <Typography variant="subtitle2" noWrap>
                              {moment(date).format("lll")}
                            </Typography>
                          </TableCell>
                          {/* <TableCell>
                               <Typography variant="subtitile2">
                                 {is_sandbox=== true ? "Yes" : "No"}
                               </Typography> 
                          </TableCell> */}
                          <TableCell>
                            <Typography variant="subtitile2">
                              {is_sandbox === true ? "Yes" : "No"}
                            </Typography>
                          </TableCell>

                          <TableCell align="left">
                            <MoreMenu
                              sx={{ marginLeft: "20px" }}
                              id={_id}
                              handleDelete={handleDelete}
                              handleEdit={handleEdit}
                              setEditId={setSelectedStoryId}
                              index={index}
                              setLanguage={languages}
                              setEditIndex={setEditIndex}
                              handleLanguage={handleLanguage}
                              set_is_published={setIsPublished}
                              is_published_status={row.is_published}
                              // handleLanguage={() => {
                              //   setIsPublished(row.is_published);
                              //   handleLanguage();
                              // }}
                              title="story?"
                              // isDefault={track_of_the_day}
                              tooltip="Meditation of the day can't be deleted"
                            />
                          </TableCell>
                        </TableRow>
                      );
                    })}
                  {/* {emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={6} />
                    </TableRow>
                  )} */}
                </TableBody>
                {filterName && tracks?.length == 0 && (
                  <TableBody>
                    <TableRow>
                      <TableCell
                        align="center"
                        colSpan={6}
                        sx={{
                          py: 3,
                          width: "100%",
                        }}
                      >
                        <div
                          style={{
                            display: "flex",
                            justifyContent: "center",
                            width: "100%",
                            marginLeft: "200px",
                          }}
                        >
                          <SearchNotFound searchQuery={filterName} />
                        </div>
                      </TableCell>
                    </TableRow>
                  </TableBody>
                )}
              </Table>
            </TableContainer>
          </Scrollbar>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25, 50]}
            component="div"
            count={count}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
          />
        </Card>
        <Modal
          open={open}
          onClose={handleClose}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={style}>
            <div className="d-flex justify-content-end">
              <CloseIcon
                style={{ cursor: "pointer" }}
                onClick={() => handleClose()}
              />
            </div>

            <Divider className="my-2" />
            <img
              src={s3baseUrl + imagePath}
              alt="images"
              style={{ width: "100%" }}
            />
          </Box>
        </Modal>
        <Dialog open={openUp} onClose={handleCloseUp}>
          <DialogTitle>{"Preview Story"}</DialogTitle>
          <DialogContent>
            <div className="player-wrapper">
              {selectedObject.story_type === "IMAGE" ? (
                <img
                  style={{ width: "100%" }}
                  src={s3baseUrl + selectedObject.image}
                  alt=""
                />
              ) : (
                <ReactPlayer
                  className="react-player"
                  url={s3baseUrl + selectedObject.video_url}
                  width="100%"
                  height="100%"
                  controls
                />
              )}
            </div>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseUp} color="primary">
              Close
            </Button>
          </DialogActions>
        </Dialog>
      </Container>
    </Page>
  );
}
